import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../Components/Header';
import Newsletter from '../Components/Newsletter';
import Footer from '../Components/refactor/Footer';
import { removeRedirect, getRedirectLink } from '../utils/redirect';
import CheckSession from './CheckSession';
// import lock from '../configs/authLock';

const CommonLayout = ({ children }) => {
  // lock.hide();
  const location = useLocation();
  const { pathname } = location;
  if (pathname === getRedirectLink()) {
    removeRedirect();
  }
  return (
    <>
      <Header />
      <CheckSession></CheckSession>
      {children}
      <Newsletter
        sx={{
          bgcolor: { xs: '#F8F8F8', md: '#f6f6f6' },
          justifyContent: 'center'
        }}
      />
      <Footer />
    </>
  );
};

export default CommonLayout;
