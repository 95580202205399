import React from 'react';
import { MenuItem, FormControl, Select, styled } from '@mui/material';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import sortIcon from '../../resources/sort.svg';
import { setSortby } from '../ProductLists/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { getProductsData } from '../ProductLists/actions';
import { sortingCriteria } from '../../variables';
import { getStoreIds } from '../../utils/getStoreIds';
import './Sort.css';

const StyledSelect = styled(Select)(`
  & .${outlinedInputClasses.notchedOutline} {
    border:none
  }
  &:hover .${outlinedInputClasses.notchedOutline} {
    border-color: blue;
  }
  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border-color: lime;
  }
`);

const SortModule = ({ products }) => {
  const dispatch = useDispatch();
  const { allStoreLocations } = useSelector(state => state.storeReducer);
  const { selectedItemsFilter } = useSelector(state => state.productsReducer);

  const handleChange = event => {
    const {
      target: { value }
    } = event;
    dispatch(setSortby(value));
    dispatch(
      getProductsData({
        storeId: getStoreIds(
          allStoreLocations.map(s => s.id),
          selectedItemsFilter
        )
      })
    );
  };

  return (
    <FormControl sx={{ width: 80 }} className="filter-menu">
      <StyledSelect
        defaultValue={sortingCriteria.DATE_DESC}
        onChange={handleChange}
        renderValue={sel => (
          <>
            <img className="img-responsive-sort" src={sortIcon} alt="sort" />
            <span className="font-color-product-list">Sort</span>
          </>
        )}
      >
        <MenuItem value={sortingCriteria.DATE_ASC}>Date: Ascending</MenuItem>
        <MenuItem value={sortingCriteria.DATE_DESC}>Date: Descending</MenuItem>
        <MenuItem value={sortingCriteria.PRICE_ASC}>
          Price: low to high
        </MenuItem>
        <MenuItem value={sortingCriteria.PRICE_DESC}>
          Price: high to low
        </MenuItem>
      </StyledSelect>
    </FormControl>
  );
};

export default SortModule;
