import { Outlet } from 'react-router-dom';
import Header from '../Components/Header';
import FooterAuth from '../Components/FooterAuth';
import './AuthLayout.css';

const Layout = () => {
  return (
    <div
      style={{
        position: 'relative',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: `#FF5943 url('/bg.svg')`,
        height: '100vh'
      }}
    >
      <Header transparent={true} excludeLogo={true}/>
      <Outlet />
      <FooterAuth />
    </div>
  );
};

export default Layout;
