import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import ThriftzeeLogo from '../../../../resources/thriftzee-logo.svg';

const FooterLeft = () => {
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={{ xs: 'center', md: 'space-between' }}
      textAlign={{ xs: 'center', md: 'left' }}
    >
      <Grid item xs={12} md={6}>
        <img src={ThriftzeeLogo} alt="logo" height={60} />
      </Grid>
      {!mediumScreen && (
        <Grid item md={12}>
          &copy; {new Date().getFullYear()} thriftzee.com{' '}
          <Link
            to="/privacy"
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <span className="footer-privacy-color">Privacy Policy</span>
          </Link>
        </Grid>
      )}
    </Grid>
  );
};

export default FooterLeft;
