import { Grid, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CheckBox } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { resetStatus } from '../reducer';

const StyledButton = styled(Button)(({ theme }) => ({
  height: '50px',
  textTransform: 'none',
  borderRadius: '30px',
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '23px',
  letterSpacing: '-0.333333px',
  width: '259px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main
  }
}));

const SuccessMessage = ({ setShowSuccess }) => {
  const dispatch = useDispatch();

  return (
    <Grid container flexDirection="column" spacing={6} alignItems="center">
      <Grid item container flexWrap="nowrap" xs={12} md={8} lg={6}>
        <Grid item sx={{ mr: { sx: 0, md: '40px' } }}>
          <CheckBox sx={{ color: '#8ABA02', fontSize: '70px' }} />
        </Grid>
        <Grid item container flexDirection="column" justifyContent="center">
          <Typography component="h1" fontSize="20px" fontWeight="bold">
            Thanks for contacting us!
          </Typography>
          <Typography component="h2" fontSize="16px">
            We will get back to you as soon as possible!
          </Typography>
        </Grid>
      </Grid>
      <Grid item alignItems="center" justifyContent="center">
        <StyledButton
          variant="contained"
          color="secondary"
          sx={{ mb: { xs: '10px', md: 0 }, mr: { xs: 0, md: '20px' } }}
          onClick={() => {
            setShowSuccess(false);
            dispatch(resetStatus());
            setShowSuccess(true);
          }}
        >
          Okay
        </StyledButton>
      </Grid>
    </Grid>
  );
};

export default SuccessMessage;
