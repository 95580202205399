import React from 'react';
import { Container, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CommonLayout from '../../Layouts/CommonLayout';
import CommonHeader from '../../Components/CommonHeader';
import Headline1 from './components/Headline1';
import './Privacy.css';

const Privacy = () => {
  document.title = 'Thriftzee Privacy policy';
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <CommonLayout>
      <CommonHeader>Privacy Policy</CommonHeader>
      <div className={mdScreen ? 'bid-history-mb' : 'bid-history-lg'}>
        <Container maxWidth="lg" sx={{ py: 1 }}>
          <div className="container-div">
            <div className="content">
              <Headline1 />
            </div>
          </div>
        </Container>
      </div>
    </CommonLayout>
  );
};

export default Privacy;
