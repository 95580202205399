import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, ButtonBase, InputBase, Stack, Typography } from '@mui/material';
import { AccessTime as AccessTimeIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';
// import lock from '../../../configs/authLock';
import { loginUrl, auctionStatuses } from '../../../variables';
import BidCountdown from './BidCountdown';
import { placeBid } from '../actions';
import { setRedirect } from '../../../utils/redirect';
import {
  getQuickBidAmount,
  setQuickBidAmount,
  removeQuickBidAmount
} from '../../../utils/quickBidAmount';

const prevValue = getQuickBidAmount();
removeQuickBidAmount();

const ProductPlaceBid = ({ product, setAuctionOver }) => {
  // lock.hide();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const bidHistory = useSelector(state => state.bidHistoryReducer);
  const { isLoggedIn } = useSelector(state => state.loginReducer);
  const [quickBidValue, setquickBidValue] = useState(
    prevValue ? +prevValue : ''
  );
  const [errorText, setErrorText] = useState('');
  const [minBid, setMinBid] = useState(false);
  const [minBidTimesFive, setMinBidTimesFive] = useState(false);

  const handleQuickBidChange = event => {
    setquickBidValue(event.target.value);
    const bid = parseInt(event.target.value);

    if (bid) {
      if (product.totalBids > 0) {
        if (
          bid <
          parseInt(product.lastBid) + parseInt(product.minimumBidIncrease)
        ) {
          setErrorText('Bid amount is not enough!');
        } else {
          setErrorText('');
        }
      } else {
        if (
          bid <
          parseInt(product.price) + parseInt(product.minimumBidIncrease)
        ) {
          setErrorText('Bid amount is not enough!');
        } else {
          setErrorText('');
        }
      }
    }
  };

  const handleQuickBid = productData => {
    if (productData.endDate < new Date().getTime()) {
      return setAuctionOver(true);
    }

    if (isLoggedIn) {
      dispatch(placeBid({ ...productData, bidAmount: quickBidValue }));
      setquickBidValue('');
      setMinBidTimesFive(false);
      setMinBid(false);
    } else {
      setRedirect(pathname);
      setQuickBidAmount(quickBidValue);
      navigate(loginUrl);
    }
  };
  useEffect(() => {
    if (product.endDate < new Date().getTime()) {
      setAuctionOver(true);
    }
  }, [product.endDate, setAuctionOver]);

  return (
    <Stack
      spacing={3}
      sx={{
        bgcolor: '#E4F2F4',
        px: { xs: 3, lg: 4, xl: 5 },
        py: 4,
        borderRadius: '10px'
      }}
    >
      <Stack
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          textAlign: { xs: 'center', md: 'left' }
        }}
      >
        <Typography
          component="h2"
          fontSize={{ xs: '16px', md: '24px', lg: '30px' }}
          fontWeight="700"
          lineHeight="36.16px"
        >
          <Typography
            component="span"
            fontSize="16px"
            fontWeight="400"
            lineHeight="18.75px"
          >
            Current Bid:{' '}
          </Typography>
          ${product.lastBid}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <AccessTimeIcon
            sx={{
              fontSize: '20px',
              color: '#FF5943'
            }}
          />
          <BidCountdown date={product.endDate} />
        </Stack>
      </Stack>

      <Box>
        <Typography
          component="h3"
          fontSize="14px"
          fontWeight="400"
          lineHeight="16.41px"
          pb={1}
        >
          Minimum Bid Increase ${product.minimumBidIncrease}
        </Typography>
        <Box className="quickbid-group-input">
          <InputBase
            disabled={
              product.auctionStatus === auctionStatuses.CLOSED ||
              product.auctionStatus === auctionStatuses.CANCELLED
            }
            type="number"
            className="quickbid-input"
            value={quickBidValue}
            onChange={handleQuickBidChange}
          />
          <ButtonBase
            disabled={
              minBid ||
              product.auctionStatus === auctionStatuses.CLOSED ||
              bidHistory.placeBidStatus === 'pending' ||
              product.auctionStatus === auctionStatuses.CANCELLED
            }
            className="quickbid-button quickbid-button-left"
            onClick={() => {
              if (isLoggedIn) {
                setquickBidValue(
                  parseInt(product.lastBid) +
                    parseInt(product.minimumBidIncrease)
                );
                setErrorText('');
                setMinBid(true);
                setMinBidTimesFive(false);
              } else {
                setRedirect(pathname);
                setQuickBidAmount(quickBidValue);
                navigate(loginUrl);
              }
            }}
          >
            + {product.minimumBidIncrease}
          </ButtonBase>
          <ButtonBase
            disabled={
              minBidTimesFive ||
              product.auctionStatus === auctionStatuses.CLOSED ||
              bidHistory.placeBidStatus === 'pending' ||
              product.auctionStatus === auctionStatuses.CANCELLED
            }
            className="quickbid-button quickbid-button-right"
            onClick={() => {
              if (isLoggedIn) {
                setquickBidValue(
                  parseInt(product.lastBid) + product.minimumBidIncrease * 5
                );
                setErrorText('');
                setMinBidTimesFive(true);
                setMinBid(false);
              } else {
                setRedirect(pathname);
                setQuickBidAmount(quickBidValue);
                navigate(loginUrl);
              }
            }}
          >
            + {product.minimumBidIncrease * 5}
          </ButtonBase>
        </Box>
        <Typography
          component="h4"
          fontSize="14px"
          fontWeight="400"
          lineHeight="16.41px"
          color="#ff0000"
        >
          {errorText && `* ${errorText}`}
        </Typography>
        <LoadingButton
          loading={bidHistory.placeBidStatus === 'pending'}
          disabled={
            errorText.length ||
            !quickBidValue ||
            product.auctionStatus === auctionStatuses.CLOSED ||
            product.auctionStatus === auctionStatuses.CANCELLED
          }
          variant="contained"
          color="secondary"
          disableElevation
          className="quickbid-submit"
          onClick={() => handleQuickBid(product)}
        >
          Bid
        </LoadingButton>
      </Box>
    </Stack>
  );
};

export default ProductPlaceBid;
