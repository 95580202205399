import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF5943',
      light: '#f6f6f6'
    },
    secondary: {
      main: '#193C46'
    }
  },
  typography: {
    allVariants: {
      color: '#193C46'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1248,
      xl: 1536
    }
  }
});

export default theme;
