const getUserPoll = async poll => {
  return await new Promise((resolve, reject) => {
    const user = poll.getCurrentUser();
    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          reject();
        } else {
          const attributes = await new Promise((resolve, reject) => {
            user.getUserAttributes((err, attributes) => {
              if (err) {
                reject(err);
              } else {
                const results = {};
                // console.log('attributes', attributes);
                for (let attribute of attributes) {
                  const { Name, Value } = attribute;
                  results[Name] = Value;
                }
                if (results['custom:type']) {
                  delete Object.assign(results, {
                    type: results['custom:type']
                  })['custom:type'];
                }

                resolve(results);
              }
            });
          });
          resolve({
            user,
            ...session,
            userMeta: { ...attributes }
          });
        }
      });
    } else {
      reject();
    }
  });
};

export default getUserPoll;
