const setQuickBidAmount = amount => {
  sessionStorage.setItem('quickAmount', JSON.stringify(amount));
};

const isNoQuickBidAmount = () => {
  return sessionStorage.getItem('quickAmount') === null;
};
const getQuickBidAmount = () => {
  return JSON.parse(sessionStorage.getItem('quickAmount'));
};
const removeQuickBidAmount = () => {
  sessionStorage.removeItem('quickAmount');
};

export {
  setQuickBidAmount,
  getQuickBidAmount,
  removeQuickBidAmount,
  isNoQuickBidAmount
};
