import { Box, Modal } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: 'auto', md: '44%' },
  minWidth: 300,
  maxWidth: '620px',
  bgcolor: 'white',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
  p: 1,
  py: { md: '40px' },
  borderRadius: '10px',
  outline: 'none'
};

const CommonSmallModal = ({ open, children }) => (
  <Modal
    open={open}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>{children}</Box>
  </Modal>
);

export default CommonSmallModal;
