import { createSlice } from '@reduxjs/toolkit';
import { getDefaultFeatureProducts, getFeatureProducts } from './action';

const initialState = {
  featuredDetails: {},
  featureProductStatus: 'initial',
  error: ''
};

export const featuredSlice = createSlice({
  name: 'featuredContentReducer',
  initialState,
  extraReducers: {
    [getFeatureProducts.pending]: state => ({
      ...state,
      featureProductStatus: 'pending'
    }),
    [getFeatureProducts.fulfilled]: (state, action) => ({
      ...state,
      featureProductStatus: 'success',
      featuredDetails: action.payload
    }),
    [getFeatureProducts.rejected]: (state, action) => ({
      ...state,
      featureProductStatus: 'error',
      error: action.error.messsage
    }),
    [getDefaultFeatureProducts.pending]: state => ({
      ...state,
      featureProductStatus: 'pending'
    }),
    [getDefaultFeatureProducts.fulfilled]: (state, action) => ({
      ...state,
      featureProductStatus: 'success',
      featuredDetails: action.payload
    }),
    [getDefaultFeatureProducts.rejected]: (state, action) => ({
      ...state,
      featureProductStatus: 'error',
      error: action.error.messsage
    })
  }
});

export const featureContent = state => state.featuredContentReducer;
const { reducer } = featuredSlice;
export default reducer;
