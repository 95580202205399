import { createSlice } from '@reduxjs/toolkit';
import { getBidHistory } from './action';
import { placeBid } from '../ProductDetails/actions';
import { fetchStatus } from '../../variables';

const initialState = {
  bids: [],
  status: 'idle',
  productStatusBidHistory: fetchStatus.INITIAL,
  placeBidStatus: 'idle',
  placeBidError: '',
  error: ''
};

export const bidHistorySlice = createSlice({
  name: 'bidHistory',
  initialState,
  reducers: {
    setBidStatus: (state, action) => ({
      ...state,
      placeBidStatus: action.payload
    }),
    setProductStatusBidHistory: (state, action) => ({
      ...state,
      productStatusBidHistory: action.payload
    })
  },
  extraReducers: {
    [getBidHistory.pending]: state => {
      state.status = 'pending';
    },
    [getBidHistory.fulfilled]: (state, action) => {
      state.status = 'success';
      state.bids = action.payload;
    },
    [getBidHistory.rejected]: (state, action) => {
      state.status = 'error';
      state.error = action.error.message;
    },
    [placeBid.pending]: state => {
      state.placeBidStatus = 'pending';
    },
    [placeBid.fulfilled]: state => {
      state.placeBidStatus = 'success';
    },
    [placeBid.rejected]: (state, action) => {
      state.placeBidStatus = 'error';
      state.placeBidError = action.error.message;
    }
  }
});
const { actions, reducer } = bidHistorySlice;
export const { setBidStatus, setProductStatusBidHistory } = actions;
export default reducer;
