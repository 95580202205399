import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, CircularProgress, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import './ForgotPassword.scss';
import logo from '../../resources/logo.png';
import UserPool from '../../configs/cognitoPoll';
import { CognitoUser } from 'amazon-cognito-identity-js';
const ForgotPassword = () => {
  const [authError, setAuthError] = useState(null);
  const [authLoading, setAuthLoading] = useState(false);
  const [passwordField, setPasswordField] = useState(false);
  const [email, setEmail] = useState(null);
  const [sendEmailSuccess, setSendEmailSuccess] = useState('');
  const [passResetSucess, setPassResetSucess] = useState('');

  const validationSchema = yup.object({
    email: yup.string().trim().email().required()
  });

  const resetValidationSchema = yup.object({
    resetCode: yup.number().required().typeError('Code must be a number'),
    password: yup.string().trim().required(),
    confirmPassword: yup
      .string()
      .trim()
      .required()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  });

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({ resolver: yupResolver(validationSchema) });

  const {
    handleSubmit: reseHandleSubmit,
    control: resetControl,
    formState: { errors: resetErrors }
  } = useForm({ resolver: yupResolver(resetValidationSchema) });

  const onSubmit = data => {
    setAuthLoading(true);
    setSendEmailSuccess('');
    setAuthError(null);
    setEmail(data.email);
    const user = new CognitoUser({
      Username: data.email,
      Pool: UserPool
    });
    user.forgotPassword({
      onSuccess: function (data) {
        setAuthLoading(false);
        setPasswordField(true);
      },
      onFailure: function (err) {
        setAuthError(err.message);
        setAuthLoading(false);
        setSendEmailSuccess('');
      },
      inputVerificationCode: function (data) {
        setAuthLoading(false);
        setPasswordField(true);
        setSendEmailSuccess(
          `Please check your email. We have sent confirmation code`
        );
      }
    });
  };

  const onResetSubmit = data => {
    // console.log(email);
    setAuthError(null);
    setAuthLoading(true);
    setSendEmailSuccess('');
    setPassResetSucess('');
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool
    });
    user.confirmPassword(data.resetCode.toString(), data.password, {
      onSuccess() {
        setAuthLoading(false);
        setPassResetSucess(
          'Your Password Reset Successfully. Please Sign In Again!'
        );
      },
      onFailure(err) {
        setAuthError(err.message);
        setPassResetSucess('');
        setAuthLoading(false);
        setSendEmailSuccess('');
      }
    });
  };
  return (
    <>
      <div className="cognito-container">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%', padding: '10px' }}
        >
          <div className="brand-logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="reset-header">
            <h3>
              {passwordField
                ? passResetSucess === '' && 'Set new password'
                : 'Reset your password'}
            </h3>
            {!passwordField && (
              <p>
                Please enter your email address. We will send you an
                verification code.
              </p>
            )}
          </div>
          {!passwordField && (
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '50%' }}>
              <Box display="flex" flexDirection="column">
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ marginTop: '15px', width: '100%' }}
                      value={value}
                      disabled={authLoading}
                      onChange={onChange}
                      id="email"
                      label="Email"
                      placeholder="yours@example.com"
                      size="small"
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : null}
                    />
                  )}
                />

                <Button
                  disabled={authLoading}
                  type="submit"
                  sx={{ marginTop: '15px' }}
                  variant="contained"
                >
                  Send Email
                  {authLoading && (
                    <CircularProgress
                      style={{
                        width: '20px',
                        height: '20px',
                        marginLeft: '8px'
                      }}
                      color="inherit"
                    />
                  )}
                </Button>

                <Link className="forgetLink" to="/login">
                  Remember It ? Sign In here
                </Link>

                {authError !== null && (
                  <Alert
                    sx={{
                      marginTop: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    severity="error"
                  >
                    {authError}
                  </Alert>
                )}
              </Box>
            </form>
          )}
          {passwordField && passResetSucess === '' && (
            <form
              onSubmit={reseHandleSubmit(onResetSubmit)}
              style={{ width: '50%' }}
            >
              <Box display="flex" flexDirection="column">
                <Controller
                  name="resetCode"
                  control={resetControl}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ marginTop: '15px', width: '100%' }}
                      value={value}
                      disabled={authLoading}
                      onChange={onChange}
                      id="resetCode"
                      label="Confirmation Code"
                      placeholder="Enter your Confirmation code"
                      size="small"
                      error={!!resetErrors.resetCode}
                      helperText={
                        resetErrors.resetCode
                          ? resetErrors.resetCode.message
                          : null
                      }
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={resetControl}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ marginTop: '15px', width: '100%' }}
                      value={value}
                      disabled={authLoading}
                      onChange={onChange}
                      id="password"
                      type="password"
                      label="New Password"
                      placeholder="Enter your new password"
                      size="small"
                      error={!!resetErrors.password}
                      helperText={
                        resetErrors.password
                          ? resetErrors.password.message
                          : null
                      }
                    />
                  )}
                />

                <Controller
                  name="confirmPassword"
                  control={resetControl}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ marginTop: '15px', width: '100%' }}
                      value={value}
                      disabled={authLoading}
                      onChange={onChange}
                      type="password"
                      id="confirmPassword"
                      label="Confirm Password"
                      placeholder="COnfirm your password"
                      size="small"
                      error={!!resetErrors.confirmPassword}
                      helperText={
                        resetErrors.confirmPassword
                          ? resetErrors.confirmPassword.message
                          : null
                      }
                    />
                  )}
                />

                <Button
                  disabled={authLoading}
                  type="submit"
                  sx={{ marginTop: '15px' }}
                  variant="contained"
                >
                  Reset Password
                  {authLoading && (
                    <CircularProgress
                      style={{
                        width: '20px',
                        height: '20px',
                        marginLeft: '8px'
                      }}
                      color="inherit"
                    />
                  )}
                </Button>

                <Link className="forgetLink" to="/login">
                  {passResetSucess !== ''
                    ? 'Sign In'
                    : 'Remember It ? Sign In here'}
                </Link>
                {sendEmailSuccess !== '' && (
                  <Alert
                    sx={{
                      marginTop: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    severity="success"
                  >
                    {sendEmailSuccess}
                  </Alert>
                )}
                {authError !== null && (
                  <Alert
                    sx={{
                      marginTop: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    severity="error"
                  >
                    {authError}
                  </Alert>
                )}
              </Box>
            </form>
          )}
          {passResetSucess !== '' && (
            <>
              <Alert
                sx={{
                  marginTop: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                severity="success"
              >
                {passResetSucess}
              </Alert>
              <Link to="/login" style={{ textDecoration: 'none' }}>
                <Button sx={{ marginTop: '15px' }} variant="contained">
                  Sign In
                </Button>
              </Link>
            </>
          )}
        </Box>
      </div>
    </>
  );
};

export default ForgotPassword;
