import { createAsyncThunk } from '@reduxjs/toolkit';
import thriftzee from '../../services/thrift';
import { csrf } from '../../utils/csrf';

export const submitContactInfo = createAsyncThunk(
  'contact/submitContactInfo',
  async data => {
    const bearerToken = await csrf();
    const config = {
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    };
    const response = await thriftzee.post('/public/contact', data, config);

    return response.data.data;
  }
);
