import { createAsyncThunk } from '@reduxjs/toolkit';
import thriftzee from '../../services/thrift';

export const getPurchaseHistory = createAsyncThunk(
  'user/fetchPurchaseHistory',
  async (_, { getState }) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getState().loginReducer.token}`
      }
    };
    const response = await thriftzee.get(`/customer/purchase-history`, config);
    return response.data.data;
  }
);
