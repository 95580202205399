import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import LeftSection from './components/LeftSection';
import RightSection from './components/RightSection';
import './HeroStyle.css';

const HeroSection = () => {
  const { pathname } = useLocation();
  return (
    <div className={pathname.includes('products') ? 'hero-bg-color-product-list' : 'hero-bg-color'}>
      <Container maxWidth="lg" sx={{ py: { xs: 2, md: 6 } }}>
        <Grid
          container
          justifyContent="space-between"
          direction={{ xs: 'column-reverse', md: 'row' }}
        >
          <Grid item xs={12} md={6}>
            <LeftSection />
          </Grid>
          <Grid item xs={12} md={6}>
            <RightSection />
          </Grid>
        </Grid>
      </Container>
    </div >
  );
};

export default HeroSection;
