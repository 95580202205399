import { yupResolver } from '@hookform/resolvers/yup';

import { Alert, Box, Button, CircularProgress, TextField } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import './signInFrom.scss';
const SignInForm = ({ onSubmit, authError, authLoading }) => {
  const validationSchema = yup.object({
    email: yup.string().trim().email().required(),
    password: yup.string().trim().required()
  });

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({ resolver: yupResolver(validationSchema) });

  return (
    <>
      <form
        // classes={classes}
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: '50%' }}
      >
        <Box display="flex" flexDirection="column">
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField
                sx={{ marginTop: '15px', width: '100%' }}
                value={value}
                disabled={authLoading}
                onChange={onChange}
                id="email"
                label="Email"
                placeholder="yours@example.com"
                size="small"
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : null}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField
                sx={{ marginTop: '15px' }}
                value={value}
                disabled={authLoading}
                onChange={onChange}
                id="password"
                label="Password"
                type="password"
                placeholder="Your password"
                size="small"
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : null}
              />
            )}
          />
          <Button
            disabled={authLoading}
            type="submit"
            sx={{ marginTop: '15px' }}
            variant="contained"
          >
            Sign In
            {authLoading && (
              <CircularProgress
                style={{ width: '20px', height: '20px', marginLeft: '8px' }}
                color="inherit"
              />
            )}
          </Button>

          <Link className="forgetLink" to="/forgot-password">
            Don't remember your password?
          </Link>

          {authError !== null && (
            <Alert
              sx={{
                marginTop: '15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              severity="error"
            >
              {authError}
            </Alert>
          )}
        </Box>
      </form>
    </>
  );
};

export default SignInForm;
