import React, { useState } from 'react';
import {
  MenuItem,
  ListSubheader,
  FormControl,
  Select,
  Checkbox,
  styled,
  ListItemText,
  Radio,
  FormControlLabel,
  RadioGroup
} from '@mui/material';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedItemsFilter } from '../ProductLists/reducer';
import { productsStatus } from '../../variables';
import filterIcon from '../../resources/filter.svg';
import { getProductsData } from '../ProductLists/actions';
import { getStoreIds } from '../../utils/getStoreIds';
import './Filter.css';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const StyledSelect = styled(Select)(`
  & .${outlinedInputClasses.notchedOutline} {
    border:none
  }
  &:hover .${outlinedInputClasses.notchedOutline} {
    border-color: blue;
  }
  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border-color: lime;
  }
`);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      font: 2
    }
  }
};

const STATUS = [
  { name: 'All', id: productsStatus.ALL },
  { name: 'Available', id: productsStatus.AVAILABLE }
];
const TYPES = [
  { name: 'Auction', id: 'auction' },
  { name: 'Buy', id: 'buy' }
];

const Filter = ({ type }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const { allStoreLocations } = useSelector(state => state.storeReducer);
  const { selectedItemsFilter } = useSelector(state => state.productsReducer);

  const [radioStatus, setRadioStatus] = useState(
    selectedItemsFilter.includes(productsStatus.AVAILABLE)
      ? productsStatus.AVAILABLE
      : productsStatus.ALL
  );

  const handleChange = event => {
    const {
      target: { value }
    } = event;
    dispatch(setSelectedItemsFilter(value));
  };

  const handleRadioChange = e => {
    const {
      target: { value }
    } = e;
    setRadioStatus(value);
    let newSelected = [...selectedItemsFilter];

    if (value === productsStatus.ALL) {
      let indx = newSelected.indexOf(productsStatus.AVAILABLE);
      newSelected.splice(indx, 1);
    } else {
      let indx = newSelected.indexOf(productsStatus.ALL);
      newSelected.splice(indx, 1);
    }

    newSelected.push(value);

    dispatch(setSelectedItemsFilter(newSelected));
  };

  useEffect(() => {
    dispatch(
      getProductsData({
        storeId: getStoreIds(
          allStoreLocations.map(s => s.id),
          selectedItemsFilter
        )
      })
    );
  }, [dispatch, allStoreLocations, selectedItemsFilter]);

  return (
    <>
      <FormControl sx={{ width: 80 }} className="filter-menu">
        <StyledSelect
          onClick={() => {
            setOpen(!open);
          }}
          multiple
          value={selectedItemsFilter}
          defaultChecked="false"
          onChange={handleChange}
          open={open}
          renderValue={sel => (
            <>
              <img
                className="img-responsive"
                id="filter-icon-color"
                src={filterIcon}
                height="12px"
                alt="filter"
              />
              <span className="font-color-product-list">Filter</span>
            </>
          )}
          MenuProps={MenuProps}
        >
          <ListSubheader>Store</ListSubheader>
          {allStoreLocations.map(store => (
            <MenuItem key={store.id} value={store.id}>
              <Checkbox
                checked={selectedItemsFilter.indexOf(store.id) > -1}
                inputProps={{ classes: 'mui-checkbox-focus' }}
              />
              <ListItemText primary={store.name} />
            </MenuItem>
          ))}

          <FormControl>
            <ListSubheader>Status</ListSubheader>
            <RadioGroup
              defaultValue={radioStatus}
              onChange={handleRadioChange}
              value={radioStatus}
            >
              {STATUS.map(s => (
                <FormControlLabel
                  value={s.id}
                  control={<Radio />}
                  sx={{ marginLeft: 2 }}
                  label={s.name}
                  key={s.id}
                ></FormControlLabel>
              ))}
            </RadioGroup>
          </FormControl>

          <ListSubheader>Type</ListSubheader>
          {TYPES.map(t => (
            <MenuItem
              key={t.id}
              value={t.id}
              disabled={searchParams.get('type') ? true : false}
            >
              <Checkbox checked={selectedItemsFilter.indexOf(t.id) > -1} />
              <ListItemText primary={t.name} />
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </>
  );
};

export default Filter;
