const setProduct = product => {
  sessionStorage.setItem('product', JSON.stringify(product));
};

const isNoProduct = () => {
  return sessionStorage.getItem('product') === null;
};

const getProduct = () => {
  return JSON.parse(sessionStorage.getItem('product'));
};

const removeProduct = () => {
  sessionStorage.removeItem('product');
};

export { setProduct, isNoProduct, getProduct, removeProduct };
