import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from '../../configs/config';
import { setToken } from '../Login/reducer';
import { csrf } from '../../utils/csrf';

export const getStoreLocationsData = createAsyncThunk(
  'storeReducer/fetchAllStores',
  async (latlon, { getState }) => {
    const { token } = getState().loginReducer;
    const { latitude, longitude } = latlon;
    let bearerToken;
    if (!token) {
      const csrfToken = await csrf();
      bearerToken = csrfToken;
      setToken(csrfToken);

      const { data } = await axios.post(
        `${api}/public/stores/nearby`,
        { latitude, longitude },
        { headers: { authorization: `Bearer ${bearerToken}` } }
      );
      return data.data.Items;
    } else {
      bearerToken = token;
      const { data } = await axios.post(
        `${api}/stores/nearby`,
        { latitude, longitude },
        { headers: { authorization: `Bearer ${bearerToken}` } }
      );
      return data.data.Items;
    }
  }
);

// export const getStoreLocationsData = createAsyncThunk(
//   'storeLocations/fetchStoreLocations',
//   async (latlong, { getState }) => {
//     const config = {
//       headers: {
//         Authorization: `Bearer ${getState().loginReducer.token}`
//       }
//     };
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/stores/nearby`,
//         latlong,
//         config
//       );
//       return response.data;
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );

// export const getProductsData = createAsyncThunk(
//   'storeLocations/fetchProducts',
//   async (storeIds, { getState }) => {
//     const config = {
//       headers: {
//         Authorization: `Bearer ${getState().loginReducer.token}`
//       }
//     };
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/products/search`,
//         storeIds,
//         config
//       );
//       return response.data;
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );
