// import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { store, persistor } from './store';
import './index.css';
import theme from './theme';
import App from './App';
import { sentryEnvironment } from './variables';

Sentry.init({
  dsn: 'https://7cffea6381b74ca1ada334ff2fe8c3d0@o4504683436048384.ingest.sentry.io/4504683445813248',
  integrations: [new BrowserTracing()],
  environment: sentryEnvironment,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});
const container = document.getElementById('root');
const app = createRoot(container);

app.render(
  // <StrictMode>
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ThemeProvider>
  //</StrictMode>
);
