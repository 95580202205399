const setRedirect = link => {
  sessionStorage.setItem('redirectLink', JSON.stringify(link));
};

const isNoRedirection = () => {
  return sessionStorage.getItem('redirectLink') === null;
};

const getRedirectLink = () => {
  return JSON.parse(sessionStorage.getItem('redirectLink'));
};

const removeRedirect = () => {
  sessionStorage.removeItem('redirectLink');
};

export { setRedirect, isNoRedirection, removeRedirect, getRedirectLink };
