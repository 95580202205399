export const mockSkeletonData = [
  { details: 'Wall breaker from' },
  { details: 'Wall breaker from' },
  { details: 'Wall breaker from' },
  { details: 'Wall breaker from' },
  { details: 'Wall breaker from' },
  { details: 'Wall breaker from' },
  { details: 'Wall breaker from' },
  { details: 'Wall breaker from' },
  { details: 'Wall breaker from' }
];
