import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HistoryComponent from './components/HistoryComponent';
// import lock from '../../configs/authLock';
import CommonModal from '../../Components/CommonModal';
import QuickBidModal from '../../Components/QuickBid/ModalWithClose';
import QuickBid from '../../Components/QuickBid';
import CommonLayout from '../../Layouts/CommonLayout';
import { getProductDetails } from '../ProductDetails/actions';
import AuctionBuySuccess from '../../Components/productDetails/AuctionBuySuccess';
import FullScreenLoader from '../../Components/FullScreenLoader';
import CommonSmallModal from '../../Components/productDetails/CommonSmallModal';
import AuctionIsOver from '../../Components/productDetails/AuctionIsOver';
import ProductBuySuccess from './components/ProductBuySuccessful';
import { getCustomerBidHistory } from './action';
import { setBidStatus, setProductStatusBidHistory } from './reducer';
import { fetchStatus } from '../../variables';
import { setPurchaseStatus } from '../ProductDetails/reducer';

const HistoryLayout = () => {
  // lock.hide();
  const dispatch = useDispatch();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showQuickBid, setshowQuickBid] = useState(false);
  const [showBidSuccess, setShowBidSuccess] = useState(false);
  const [auctionOver, setAucitonOver] = useState(false);
  const { purchaseProductStatus, status, productDetails } = useSelector(
    state => state.productDetailsReducer
  );
  const { placeBidStatus, productStatusBidHistory } = useSelector(
    state => state.bidHistoryReducer
  );

  useEffect(() => {
    if (status === 'idle') setshowQuickBid(false);
    if (status === 'success' && productStatusBidHistory !== fetchStatus.INITIAL)
      setshowQuickBid(true);
    if (placeBidStatus === 'success') {
      setshowQuickBid(false);
      setShowBidSuccess(true);
    }
    if (purchaseProductStatus === 'success') {
      setShowSuccess(true);
    }
  }, [status, productStatusBidHistory, placeBidStatus, purchaseProductStatus]);

  const handleQuickBid = product => {
    dispatch(getProductDetails(product.productId));
    dispatch(setProductStatusBidHistory(fetchStatus.SUCCESS));
  };

  const handleOkay = () => {
    setAucitonOver(false);
    dispatch(getCustomerBidHistory());
  };

  useEffect(() => {
    return () => {
      dispatch(setBidStatus(fetchStatus.IDLE));
      dispatch(setProductStatusBidHistory(fetchStatus.INITIAL));
    };
  }, [dispatch]);

  const handleBidSuccessModalClose = () => {
    dispatch(setBidStatus(fetchStatus.IDLE));
    dispatch(setProductStatusBidHistory(fetchStatus.INITIAL));
    setShowBidSuccess(false);
    setshowQuickBid(false);
    dispatch(getCustomerBidHistory());
  };

  const handleBuySuccessModalClose = () => {
    dispatch(setPurchaseStatus(fetchStatus.IDLE));
    dispatch(setProductStatusBidHistory(fetchStatus.INITIAL));
    setShowSuccess(false);
    dispatch(getCustomerBidHistory());
  };

  return (
    <CommonLayout>
      <HistoryComponent handleQuickBid={handleQuickBid} />
      <CommonModal open={showSuccess} handleClick={handleBuySuccessModalClose}>
        <ProductBuySuccess handleClose={() => setShowSuccess(false)} />
      </CommonModal>
      {status === 'pending' && <FullScreenLoader />}
      <QuickBidModal
        open={showQuickBid}
        handleClose={() => setshowQuickBid(false)}
      >
        <QuickBid
          product={productDetails}
          setAuctionOver={setAucitonOver}
          setshowQuickBid={setshowQuickBid}
        />
      </QuickBidModal>
      <CommonModal
        open={showBidSuccess}
        handleClick={handleBidSuccessModalClose}
      >
        <AuctionBuySuccess
          type="auction"
          handleClose={() => setShowBidSuccess(false)}
        />
      </CommonModal>
      <CommonSmallModal open={auctionOver}>
        <AuctionIsOver handleOkay={handleOkay} />
      </CommonSmallModal>
    </CommonLayout>
  );
};

export default HistoryLayout;
