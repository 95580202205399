import React from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedItemsFilter } from '../../../../Modules/ProductLists/reducer';
import { productsStatus } from '../../../../variables';
import { useNavigate } from 'react-router-dom';

const FooterMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedStores } = useSelector(state => state.storeReducer);

  const menuItems = [
    { text: 'Home', link:'/' },
    { text: 'Products',  link: selectedStores.length ? '/products' : '/home'  },
    { text: 'About', link: '/about' },
    { text: 'Contact', link: '/contact' },
    // { text: 'Auction', link: selectedStores.length ? '/auction' : '/home' },
    // { text: 'Buy', link: selectedStores.length ? '/buy' : '/home' }
  ];
  const handleClick = link => {
    navigate(link);
    if (link === '/products') {
      dispatch(
        setSelectedItemsFilter([
          ...selectedStores.map(selectedStore => selectedStore.id),
          productsStatus.AVAILABLE
        ])
      );
    }
    // if (link === menuItems[2].link) {
    //   dispatch(
    //     setSelectedItemsFilter([
    //       ...selectedStores.map(selectedStore => selectedStore.id),
    //       productsStatus.AVAILABLE,
    //       productTypes.AUCTION
    //     ])
    //   );
    // }
    // if (link === menuItems[3].link) {
    //   dispatch(
    //     setSelectedItemsFilter([
    //       ...selectedStores.map(selectedStore => selectedStore.id),
    //       productsStatus.AVAILABLE,
    //       productTypes.BUY
    //     ])
    //   );
    // }
  };

  return (
    <>
      <Grid item>
        <Grid
          container
          spacing={{ xs: 4, sm: 3, md: 1 }}
          justifyContent="center"
          alignItems="center"
          textAlign={{ xs: 'center', md: 'left' }}
        >
          {menuItems.map((ele, indx) => (
            <Grid item key={indx} xs={12}>
              <span
                className="footer-link"
                onClick={() => handleClick(ele.link)}
              >
                {ele.text}
              </span>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default FooterMenu;
