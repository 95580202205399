import { useState } from 'react';
import React from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  Grid,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  Tooltip,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider
} from '@mui/material';
import { AccountCircleOutlined, Menu as MenuIcon } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../resources/thriftzee-logo.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import lock from '../configs/authLock';
// import { publicRoute } from '../configs/config';
import { logout } from '../Modules/Login/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedItemsFilter } from '../Modules/ProductLists/reducer';
import { productsStatus, productTypes } from '../variables';
import { removeBuy } from './../utils/quickBuyPopUp';
import { removeBid } from './../utils/quickBidPopUp';
import UserPool from '../configs/cognitoPoll';

const ResponsiveAppBar = props => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const { selectedStores } = useSelector(state => state.storeReducer);
  const login = useSelector(state => state.loginReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const headerBG = props.transparent ? 'transparent' : 'primary.main';
  const handleOpenNavMenu = page => {
    setShowDrawer(!showDrawer);
  };
  const settings = [
    { text: 'Bid history', link: '/bid-history' },
    { text: 'Purchase history', link: '/purchase-history' }
  ];
  // if (!publicRoute.includes(window.location.pathname)) {
  //   settings.unshift({
  //     text: 'Products',
  //     link: selectedStores.length ? '/products' : '/home'
  //   });
  // }

  const pages = {
    Home: '/',
    Products: selectedStores.length ? '/products' : '/home',
    About: '/about',
    // Auctions: selectedStores.length ? '/auction' : '/home',
    // Buy: selectedStores.length ? '/buy' : '/home',
    Contact: '/contact'
  };

  const handleAppBarClick = page => {
    removeBid();
    removeBuy();
    navigate(page, { replace: true });

    if (page === '/products' || page === '/home') {
      dispatch(
        setSelectedItemsFilter([
          ...selectedStores.map(selectedStore => selectedStore.id),
          productsStatus.AVAILABLE
        ])
      );
    }
    if (page === '/auction') {
      dispatch(
        setSelectedItemsFilter([
          ...selectedStores.map(selectedStore => selectedStore.id),
          productsStatus.AVAILABLE,
          productTypes.AUCTION
        ])
      );
    }
    if (page === '/buy') {
      dispatch(
        setSelectedItemsFilter([
          ...selectedStores.map(selectedStore => selectedStore.id),
          productsStatus.AVAILABLE,
          productTypes.BUY
        ])
      );
    }
  };

  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = link => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    // lock.logout({
    //   returnTo: window.origin
    // });
    const user = UserPool.getCurrentUser();
    if (user) {
      user.signOut();
    }
    dispatch(logout());
    // document.location.href = '/';
    setTimeout(() => navigate(0), 200);
  };
  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <AppBar
      position="static"
      sx={{
        boxShadow: 'none',
        bgcolor: headerBG,
        pt: '1%',
        zIndex: 99
      }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Grid container className="asdf" justifyContent="space-between">
            <Grid item sx={{ display: { xs: 'none', md: 'flex' } }}>
              {!props.excludeLogo && (
                <img
                  src={logo}
                  alt="logo"
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate('/')}
                />
              )}
            </Grid>
            <Grid
              item
              sx={{ display: { xs: 'flex', md: 'none' } }}
              justifyContent="flex-start"
              alignItems="center"
              xs={4}
              style={{
                visibility: pathname === '/landing' ? 'hidden' : 'visible'
              }}
            >
              <IconButton
                size="large"
                color="inherit"
                sx={{
                  minHeight: 0,
                  minWidth: 0,
                  ml: '-12px'
                }}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              sx={{ display: { xs: 'flex', md: 'none' } }}
              justifyContent="center"
              alignItems="center"
              xs={4}
            >
              <img
                src={logo}
                alt="logo"
                height="46px"
                onClick={() => navigate('/')}
                style={{ cursor: 'pointer' }}
              />
            </Grid>
            <Grid
              item
              sx={{ display: { xs: 'flex', md: 'none' } }}
              justifyContent="flex-end"
              alignItems="center"
              xs={4}
            >
              <IconButton
                size="large"
                color="inherit"
                sx={{
                  minHeight: 0,
                  minWidth: 0,
                  mr: '-12px'
                }}
                onClick={handleOpenNavMenu}
              >
                <MenuIcon />
              </IconButton>

              <Drawer
                // className="mobile-only"
                anchor="right"
                open={showDrawer}
                onClose={handleOpenNavMenu}
                PaperProps={{
                  sx: {
                    width: '60%',
                    bgcolor: '#193C46',
                    justifyContent: 'space-between',
                    display: {
                      xs: 'block',
                      md: 'none'
                    }
                  }
                }}
              >
                <div>
                  <List disablePadding>
                    <ListItem
                      sx={{ justifyContent: 'flex-end', py: 2.5, pr: 2.25 }}
                    >
                      <CloseIcon
                        sx={{ cursor: 'pointer', color: 'white' }}
                        onClick={handleOpenNavMenu}
                      />
                    </ListItem>
                    <Divider sx={{ bgcolor: '#2C5763' }} />
                    <ListItemButton component={Link} to="/">
                      <ListItemText
                        primary={
                          <Typography type="body2" style={{ color: '#FFFFFF' }}>
                            Home
                          </Typography>
                        }
                      />
                    </ListItemButton>
                    <Divider sx={{ bgcolor: '#2C5763' }} />
                    <ListItemButton
                      onClick={() => {
                        handleAppBarClick(
                          selectedStores.length ? '/products' : '/home'
                        );
                        handleOpenNavMenu();
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography type="body2" style={{ color: '#FFFFFF' }}>
                            Products
                          </Typography>
                        }
                      />
                    </ListItemButton>
                    {login.isLoggedIn && (
                      <>
                        <Divider sx={{ bgcolor: '#2C5763' }} />
                        <ListItemButton component={Link} to="/bid-history">
                          <ListItemText
                            primary={
                              <Typography
                                type="body2"
                                style={{ color: '#FFFFFF' }}
                              >
                                Bid History
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </>
                    )}
                    {login.isLoggedIn && (
                      <>
                        <Divider sx={{ bgcolor: '#2C5763' }} />
                        <ListItemButton component={Link} to="/purchase-history">
                          <ListItemText
                            primary={
                              <Typography
                                type="body2"
                                style={{ color: '#FFFFFF' }}
                              >
                                Purchase History
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </>
                    )}
                    <Divider sx={{ bgcolor: '#2C5763' }} />
                    <ListItemButton component={Link} to="/about">
                      <ListItemText
                        primary={
                          <Typography type="body2" style={{ color: '#FFFFFF' }}>
                            About
                          </Typography>
                        }
                      />
                    </ListItemButton>
                    <Divider sx={{ bgcolor: '#2C5763' }} />
                    <ListItemButton component={Link} to="/contact">
                      <ListItemText
                        primary={
                          <Typography type="body2" style={{ color: '#FFFFFF' }}>
                            Contact
                          </Typography>
                        }
                      />
                    </ListItemButton>
                    <Divider sx={{ bgcolor: '#2C5763' }} />
                    {login.isLoggedIn ? (
                      <ListItemButton onClick={handleLogout}>
                        <ListItemText
                          primary={
                            <Typography
                              type="body2"
                              style={{ color: '#FFFFFF' }}
                            >
                              Logout
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    ) : (
                      <ListItemButton
                        onClick={(handleCloseUserMenu, handleLogin)}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              type="body2"
                              style={{ color: '#FFFFFF' }}
                            >
                              Login
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    )}

                    <Divider sx={{ bgcolor: '#2C5763' }} />
                  </List>
                </div>
              </Drawer>
            </Grid>
            <Grid item sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Grid container justifyContent="flex-end">
                {Object.keys(pages).map(page => (
                  <Button
                    key={page}
                    onClick={() => handleAppBarClick(pages[page])}
                    sx={{
                      my: 2,
                      color: 'white',
                      display: 'block',
                      textTransform: 'none'
                    }}
                  >
                    {page}
                  </Button>
                ))}

                {pathname !== '/login' && (
                  <Box sx={{ display: 'flex' }}>
                    <Tooltip
                      title="Open settings"
                      style={{ backgroundColor: 'transparent' }}
                    >
                      <IconButton
                        onClick={handleOpenUserMenu}
                        sx={{ minHeight: 0, minWidth: 0, padding: 0, pl: 2 }}
                      >
                        <AccountCircleOutlined
                          sx={{ color: 'white', fontSize: '30px' }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      sx={{ mt: '55px' }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {login.isLoggedIn &&
                        settings.map(item => (
                          <MenuItem
                            key={item.text}
                            component={Link}
                            to={item.link}
                            onClick={() => handleCloseUserMenu(item.link)}
                          >
                            {item.text}
                          </MenuItem>
                        ))}
                      {login.isLoggedIn ? (
                        <MenuItem onClick={(handleCloseUserMenu, handleLogout)}>
                          Logout
                        </MenuItem>
                      ) : (
                        <MenuItem onClick={(handleCloseUserMenu, handleLogin)}>
                          Login
                        </MenuItem>
                      )}
                    </Menu>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ResponsiveAppBar;
