import React from 'react';
import StyledButton from '../../../Components/StyledButton';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import BidCountDown from '../../ProductDetails/components/BidCountdown';
import { useNavigate } from 'react-router-dom';

const Second = ({ product1, product2, handleQuickBuy, handleQuickBid }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <div className={`flex ${mdScreen ? 'flex-wrap ' : ''}`}>
      <div
        className={`flex fullHeight fullWidth justify-center  landing-bg-4 ${
          mdScreen ? 'flex-wrap mb-8' : 'gap-8'
        } ${product2 && !mdScreen && 'mr-8'}`}
      >
        <div className="flex justify-center items-center fullWidth fullHeight ">
          <img
            src={product1.image}
            alt="logo"
            className="second-grid-img"
            style={{ cursor: 'pointer' }}
            onClick={() =>
              navigate(
                `/product/${product1.type}/${product1.productId}/${product1.productName}`
              )
            }
          />
        </div>
        <div
          className={`fullWidth  px-1 ${
            mdScreen ? 'grid-item-center-align py-2 ' : 'py-1'
          }`}
        >
          <div
            className="close-on-text"
            style={{
              visibility: product1.type === 'auction' ? 'visible' : 'hidden'
            }}
          >
            Close on : <BidCountDown date={product1.endDate} />
          </div>

          <div className="big-text-1">New at Thriftzee</div>
          <div className="big-text-3 fixed-line-height">
            <span
              style={{ cursor: 'pointer' }}
              onClick={() =>
                navigate(
                  `/product/${product1.type}/${product1.productId}/${product1.productName}`
                )
              }
            >
              {product1.productName}
            </span>
            {` is available for `}{' '}
            {`${product1.type === 'buy' ? 'Purchase' : 'Auction'}`}
          </div>
          <div className="big-text-3 py-1">
            {product1.type === 'buy' ? (
              <>
                Price: <span className="big-text-1">${product1.price}</span>
              </>
            ) : (
              <>
                Current Price:{' '}
                <span className="big-text-1">${product1.lastBid}</span>
              </>
            )}
          </div>
          <div style={{ paddingBottom: '10px' }}>
            {product1.type === 'auction' ? (
              <StyledButton
                color={{ xs: '#FF5943', md: '#193C46' }}
                onClick={() => handleQuickBid(product1)}
              >
                Place Bid
              </StyledButton>
            ) : (
              <StyledButton
                color={{ xs: '#FF5943', md: '#193C46' }}
                onClick={() => handleQuickBuy(product1)}
                pm="70px"
              >
                Buy
              </StyledButton>
            )}
          </div>
        </div>
      </div>
      {product2 && (
        <div
          className={`flex fullHeight fullWidth justify-center  landing-bg-1 ${
            mdScreen ? 'flex-wrap col-reverse ' : 'gap-8 '
          }`}
        >
          <div
            className={`fullWidth px-1 ${
              mdScreen ? 'grid-item-center-align py-2 ' : 'py-1'
            }`}
          >
            <div
              className="close-on-text"
              style={{
                visibility: product2.type === 'auction' ? 'visible' : 'hidden'
              }}
            >
              Close on : <BidCountDown date={product2.endDate} />
            </div>

            <div className="big-text-1">New at Thriftzee</div>
            <div className="big-text-3 fixed-line-height">
              <span
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  navigate(
                    `/product/${product2.type}/${product2.productId}/${product2.productName}`
                  )
                }
              >
                {product2.productName}
              </span>
              {` is available for `}{' '}
              {`${product2.type === 'buy' ? 'Purchase' : 'Auction'}`}
            </div>
            <div className="big-text-3 py-1">
              {product2.type === 'buy' ? (
                <>
                  Price: <span className="big-text-1">${product2.price}</span>
                </>
              ) : (
                <>
                  Current Price:{' '}
                  <span className="big-text-1">${product2.lastBid}</span>
                </>
              )}
            </div>
            <div style={{ paddingBottom: '10px' }}>
              {product2.type === 'auction' ? (
                <StyledButton
                  color={{ xs: '#FF5943', md: '#193C46' }}
                  onClick={() => handleQuickBid(product2)}
                >
                  Place Bid
                </StyledButton>
              ) : (
                <StyledButton
                  color={{ xs: '#FF5943', md: '#193C46' }}
                  onClick={() => handleQuickBuy(product2)}
                  pm="70px"
                >
                  Buy
                </StyledButton>
              )}
            </div>
          </div>
          <div className="flex justify-center items-center fullWidth fullHeight ">
            <img
              src={product2.image}
              alt="logo"
              className="second-grid-img"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                navigate(
                  `/product/${product2.type}/${product2.productId}/${product2.productName}`
                )
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Second;
