import { createSlice } from '@reduxjs/toolkit';
import {
  purchasedProduct,
  getProductDetails,
  purchaseBidProduct
} from './actions';

const initialState = {
  productDetails: {},
  bidLoading: false,
  bidDetails: {},
  status: 'idle',
  bidHistory: [],
  purchaseProductStatus: 'idle',
  error: null
};

export const productDetailsSlice = createSlice({
  name: 'productDetailsReducer',
  initialState,
  reducers: {
    setProductDetails: (state, action) => {
      state.productDetails = action.payload;
    },
    setBidLoading: (state, action) => {
      state.bidLoading = action.payload;
    },
    setBidDetails: (state, action) => {
      state.bidDetails = action.payload;
    },
    setBidHistory: (state, action) => {
      state.bidHistory = action.payload;
    },
    setPurchaseStatus: (state, action) => {
      state.purchaseProductStatus = action.payload;
    },
    setProductDetailsStatus: (state, action) => {
      state.status = action.payload;
    },
    resetProductDetails: (state, action) => ({
      ...state,
      ...initialState
    })
  },
  extraReducers: {
    [purchasedProduct.pending]: (state, action) => ({
      ...state,
      purchaseProductStatus: 'pending'
    }),
    [purchasedProduct.fulfilled]: (state, action) => ({
      ...state,
      purchaseProductStatus: 'success'
    }),
    [purchasedProduct.rejected]: (state, action) => ({
      ...state,
      purchaseProductStatus: 'error'
    }),
    [purchaseBidProduct.pending]: (state, action) => ({
      ...state,
      purchaseProductStatus: 'pending'
    }),
    [purchaseBidProduct.fulfilled]: (state, action) => ({
      ...state,
      purchaseProductStatus: 'success'
    }),
    [purchaseBidProduct.rejected]: (state, action) => ({
      ...state,
      purchaseProductStatus: 'error'
    }),
    [getProductDetails.pending]: (state, action) => ({
      ...state,
      status: 'pending'
    }),
    [getProductDetails.fulfilled]: (state, action) => ({
      ...state,
      status: 'success',
      productDetails: action.payload
    }),
    [getProductDetails.rejected]: (state, action) => ({
      ...state,
      status: 'error',
      error: action.error.message
    })
  }
});

const { actions, reducer } = productDetailsSlice;
export const {
  setProductDetails,
  setBidLoading,
  setBidDetails,
  setBidHistory,
  setPurchaseStatus,
  setProductDetailsStatus,
  resetProductDetails
} = actions;
export default reducer;
