import { createSlice } from '@reduxjs/toolkit';
import { getStoreLocationsData } from './actions';

const initialState = {
  allStoreLocations: [],
  selectedStores: [],
  commonLocations: '',
  status: null,
  error: null
};

export const storeLocationsSlice = createSlice({
  name: 'storeReducer',
  initialState,
  reducers: {
    selectedStoreLocations: (state, action) => {
      state.status = 'success';
      state.selectedStores = action.payload;
    },
    filterStoreLocations: (state, action) => {
      state.selectedStores = state.allStoreLocations.filter(store =>
        action.payload.includes(store.id)
      );
    },

    commonStoreLocations: (state, action) => {
      state.status = 'success';
      state.commonLocations = action.payload;
    }
  },
  extraReducers: {
    [getStoreLocationsData.pending]: (state, action) => {
      state.status = 'pending';
    },
    [getStoreLocationsData.fulfilled]: (state, action) => {
      state.status = 'success';
      state.allStoreLocations = action.payload;
    },
    [getStoreLocationsData.rejected]: (state, action) => {
      state.status = 'error';
      state.error = action.error.message;
    }
  }
});

const { actions, reducer } = storeLocationsSlice;
export const {
  selectedStoreLocations,
  filterStoreLocations,
  commonStoreLocations
} = actions;
export default reducer;
