import { createSlice } from '@reduxjs/toolkit';
import { getPurchaseHistory } from '../Modules/purchaseHistory/action';
import { getCustomerBidHistory } from '../Modules/bidHistory/action';

const initialState = {
  bids: [],
  fetchBidStatus: 'idle',
  purchases: [],
  fetchPurchaseStatus: 'idle'
};

const userHistorySlice = createSlice({
  name: 'userHistoryReducer',
  initialState,
  extraReducers: {
    [getPurchaseHistory.pending]: (state, action) => ({
      ...state,
      purchaseStatus: 'pending'
    }),
    [getPurchaseHistory.fulfilled]: (state, action) => ({
      ...state,
      purchaseStatus: 'success',
      purchases: action.payload
    }),
    [getPurchaseHistory.rejected]: (state, action) => ({
      ...state,
      purchaseStatus: 'error',
      purchaseError: action.error.message
    }),
    [getCustomerBidHistory.pending]: (state, action) => ({
      ...state,
      fetchBidStatus: 'pending'
    }),
    [getCustomerBidHistory.fulfilled]: (state, action) => ({
      ...state,
      fetchBidStatus: 'success',
      bids: action.payload
    }),
    [getCustomerBidHistory.rejected]: (state, action) => ({
      ...state,
      fetchBidStatus: 'error',
      bidsError: action.error.message
    })
  }
});

const { reducer } = userHistorySlice;
export default reducer;
