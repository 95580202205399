const setQuickBuy = () => {
  sessionStorage.setItem('quickBuy', true);
};
const isNoBuy = () => {
  return sessionStorage.getItem('quickBuy') === null;
};

const removeBuy = () => {
  sessionStorage.removeItem('quickBuy');
};

export { setQuickBuy, removeBuy, isNoBuy };
