import { createSlice } from '@reduxjs/toolkit';
import { submitContactInfo } from './action';
import { fetchStatus } from '../../variables';

const initialState = {
  status: fetchStatus.INITIAL,
  error: ''
};

export const ContactSlice = createSlice({
  name: 'contactReducer',
  initialState,
  reducers: {
   resetStatus: state=> ({
      ...state,
      ...initialState
    }),
    setStatus: (state, action) => ({
        ...state,
        status: action.payload
    })
  },
  extraReducers: {
    [submitContactInfo.pending]: state => ({
      ...state,
      status: fetchStatus.PENDING
    }),
    [submitContactInfo.fulfilled]: state => ({
      ...state,
      status: fetchStatus.SUCCESS
    }),
    [submitContactInfo.rejected]: (state, action) => ({
      ...state,
      status: fetchStatus.ERROR,
      error: action.error.messsage
    })
  }
});

const { reducer, actions } = ContactSlice;
export const { resetStatus, setStatus } = actions;
export default reducer;
