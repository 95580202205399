import { createSlice } from '@reduxjs/toolkit';
// import thriftzee from '../../../services/thrift';

const initialState = {
  position: {},
  error: null
};

export const userPositionSlice = createSlice({
  name: 'userPositionReducer',
  initialState,
  reducers: {
    setUserPosition: (state, action) => ({
      ...state,
      position: action.payload
    })
  }
});
export const userLocation = state => state.userPositionReducer;
const { reducer, actions } = userPositionSlice;
export const { setUserPosition } = actions;
export default reducer;
