import React from 'react';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import { IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { featureContent } from '../redux/reducer';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const DefaultLocation = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { featuredDetails } = useSelector(featureContent);

  return (
    <div style={{ textAlign: mdScreen ? 'center' : 'left' }}>
      <IconButton sx={{ minHeight: 0, minWidth: 0, padding: 0 }}>
        <LocationOnSharpIcon sx={{ color: '#FF5943' }} />
      </IconButton>
      <span className="default-location-shop">
        {`${featuredDetails.storeName} | ${featuredDetails.storeAddress}  `}{' '}
        {!mdScreen && (
          <>
            <span className="divider-change-location">|</span>
            <span
              className="change-location-style"
              onClick={() => navigate('/home')}
            >
              Change location
            </span>
          </>
        )}
      </span>
      {mdScreen && (
        <div
          className="change-location-mobile"
          onClick={() => navigate('/home')}
        >
          Change location
        </div>
      )}
    </div>
  );
};

export default DefaultLocation;
