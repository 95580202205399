import { Grid, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CheckBox } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPurchaseStatus,
  setProductDetailsStatus
} from '../../ProductDetails/reducer';
import { getCustomerBidHistory } from '../../bidHistory/action';
import '../../../Components/productDetails/ModalContent.css';

const StyledButton = styled(Button)(({ theme }) => ({
  height: '50px',
  textTransform: 'none',
  borderRadius: '30px',
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '23px',
  letterSpacing: '-0.333333px',
  width: '259px'
}));

const ProductBuySuccess = ({ handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeReducer = useSelector(state => state.storeReducer);

  return (
    <Grid
      container
      flexDirection="column"
      className="modal-content-container"
      alignItems="center"
    >
      <Grid item container flexWrap="nowrap" xs={12} md={8} lg={6}>
        <Grid item sx={{ mr: { sx: 0, md: '40px' } }}>
          <CheckBox sx={{ color: '#8ABA02', fontSize: '70px' }} />
        </Grid>
        <Grid item container flexDirection="column" justifyContent="center">
          <Typography component="h1" fontSize="20px" fontWeight="bold">
            Congratulations!
          </Typography>
          <Typography component="h2" fontSize="16px">
            You have successfully paid for this auction product!
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        sx={{ flexDirection: { xs: 'column', md: 'row' } }}
        alignItems="center"
        justifyContent="center"
      >
        <StyledButton
          variant="contained"
          color="primary"
          sx={{ mb: { xs: '10px', md: 0 }, mr: { xs: 0, md: '20px' } }}
          onClick={() => {
            handleClose();
            dispatch(setPurchaseStatus('idle'));
            dispatch(setProductDetailsStatus('idle'));
            navigate(
              storeReducer.selectedStores.length ? '/products' : '/home'
            );
          }}
        >
          Continue Shopping
        </StyledButton>
        <StyledButton
          variant="contained"
          color="secondary"
          onClick={() => {
            handleClose();
            dispatch(setPurchaseStatus('idle'));
            dispatch(setProductDetailsStatus('idle'));
            dispatch(getCustomerBidHistory());
            navigate('/bid-history');
          }}
        >
          View Bid History
        </StyledButton>
      </Grid>
    </Grid>
  );
};

export default ProductBuySuccess;
