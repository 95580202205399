import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, ButtonBase, InputBase, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AccessTime as AccessTimeIcon } from '@mui/icons-material';
import BidCountdown from '../../Modules/ProductDetails/components/BidCountdown';
import { placeBid } from '../../Modules/ProductDetails/actions';
import './quickbid.css';

const QuickBid = ({ product, setAuctionOver, setshowQuickBid }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bidHistory = useSelector(state => state.bidHistoryReducer);
  const [quickBidValue, setquickBidValue] = useState('');
  const [errorText, setErrorText] = useState('');
  const [minBid, setMinBid] = useState(false);
  const [minBidTimesFive, setMinBidTimesFive] = useState(false);

  const handleQuickBidChange = event => {
    setquickBidValue(event.target.value);
    const bid = parseInt(event.target.value);
    if (bid) {
      if (product.totalBids > 0) {
        if (
          bid <
          parseInt(product.lastBid) + parseInt(product.minimumBidIncrease)
        ) {
          setErrorText('Bid amount is not enough!');
        } else {
          setErrorText('');
        }
      } else {
        if (
          bid <
          parseInt(product.price) + parseInt(product.minimumBidIncrease)
        ) {
          setErrorText('Bid amount is not enough!');
        } else {
          setErrorText('');
        }
      }
    }
  };

  const handleQuickBid = productData => {
    if (productData.endDate < new Date().getTime()) {
      setshowQuickBid(false);
      return setAuctionOver(true);
    }

    dispatch(placeBid({ ...productData, bidAmount: quickBidValue }));
  };

  return (
    <Stack
      alignItems={{ xs: 'center', md: 'flex-start' }}
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      padding={{ xs: '0px 0px 24px 0px', md: '15px 35px 35px 35px' }}
    >
      <Box
        component="img"
        src={product.image}
        alt={product.productName}
        borderRadius="10px"
        sx={{ my: { xs: 3, md: 0 }, mr: { xs: 0, md: 3.5 } }}
        width={{ xs: '260px', md: '210px' }}
        height={{ xs: '200px', md: '174px' }}
      />
      <Stack
        spacing={3}
        className="quickbid-details"
        sx={{
          width: { xs: 'auto', md: 400 }
        }}
      >
        <Stack
          sx={{
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between'
          }}
        >
          <Typography
            component="h1"
            fontSize="24px"
            fontWeight="700"
            lineHeight="28.13px"
            letterSpacing="-0.33px"
            sx={{ pb: { xs: 1, md: 0 }, cursor: 'pointer' }}
            onClick={() =>
              navigate(
                `/product/${product.type}/${product.productId}/${product.productName}`
              )
            }
          >
            {product.productName}
          </Typography>
          <Stack direction="row" alignItems="center">
            <AccessTimeIcon
              sx={{
                fontSize: '20px',
                color: '#FF5943'
              }}
            />
            <BidCountdown date={product.endDate} />
          </Stack>
        </Stack>
        <Typography
          component="h2"
          fontSize="30px"
          fontWeight="700"
          lineHeight="36.16px"
        >
          <Typography
            component="span"
            fontSize="16px"
            fontWeight="400"
            lineHeight="18.75px"
          >
            Current Bid:{' '}
          </Typography>
          ${product.lastBid}
        </Typography>
        <Box>
          <Typography
            component="h3"
            fontSize="14px"
            fontWeight="400"
            lineHeight="16.41px"
            pb={1}
          >
            Minimum Bid Increase ${product.minimumBidIncrease}
          </Typography>
          <Box className="quickbid-group-input">
            <InputBase
              type="number"
              className="quickbid-input"
              value={quickBidValue}
              onChange={handleQuickBidChange}
            />
            <ButtonBase
              disabled={minBid}
              className="quickbid-button quickbid-button-left"
              onClick={() => {
                setquickBidValue(
                  parseInt(product.lastBid) +
                    parseInt(product.minimumBidIncrease)
                );
                setErrorText('');
                setMinBid(true);
                setMinBidTimesFive(false);
              }}
            >
              +{product.minimumBidIncrease}
            </ButtonBase>
            <ButtonBase
              disabled={minBidTimesFive}
              className="quickbid-button quickbid-button-right"
              onClick={() => {
                setquickBidValue(
                  parseInt(product.lastBid) + product.minimumBidIncrease * 5
                );
                setErrorText('');
                setMinBidTimesFive(true);
                setMinBid(false);
              }}
            >
              +{product.minimumBidIncrease * 5}
            </ButtonBase>
          </Box>
          {errorText && (
            <Typography
              component="h4"
              fontSize="14px"
              fontWeight="400"
              lineHeight="16.41px"
              color="#ff0000"
              paddingTop="5px"
            >
              {`* ${errorText}`}
            </Typography>
          )}
          <LoadingButton
            loading={bidHistory.placeBidStatus === 'pending'}
            disabled={errorText !== '' || !quickBidValue}
            variant="contained"
            color="secondary"
            disableElevation
            className="quickbid-submit"
            onClick={() => handleQuickBid(product)}
          >
            Bid
          </LoadingButton>
        </Box>
      </Stack>
    </Stack>
  );
};

export default QuickBid;
