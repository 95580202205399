import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Button, CircularProgress, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import './SignUpForm.scss';
const SignUpForm = ({ onSubmit, authError, authLoading, isAcSuccess }) => {
  const validationSchema = yup.object({
    email: yup.string().trim().email().required(),
    password: yup.string().trim().required(),
    phoneNumber: yup.string().trim().required()
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm({ resolver: yupResolver(validationSchema) });
  useEffect(() => {
    if (isAcSuccess) {
      reset();
    }
  }, [isAcSuccess, reset]);
  return (
    <>
      <form
        // classes={classes}
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: '50%' }}
      >
        <Box display="flex" flexDirection="column">
          {!isAcSuccess && (
            <>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ marginTop: '15px', width: '100%' }}
                    value={value}
                    disabled={authLoading}
                    onChange={onChange}
                    id="upemail"
                    label="Email"
                    placeholder="yours@example.com"
                    size="small"
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : null}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ marginTop: '15px' }}
                    value={value}
                    disabled={authLoading}
                    onChange={onChange}
                    id="uppassword"
                    label="Password"
                    type="password"
                    placeholder="Your password"
                    size="small"
                    error={!!errors.password}
                    helperText={
                      errors.password ? errors.password.message : null
                    }
                  />
                )}
              />
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ marginTop: '15px' }}
                    value={value}
                    disabled={authLoading}
                    onChange={onChange}
                    id="upphoneNumber"
                    label="Phone Number"
                    placeholder="+14314314314"
                    size="small"
                    error={!!errors.phoneNumber}
                    helperText={
                      errors.phoneNumber
                        ? 'phone number is a required field'
                        : null
                    }
                  />
                )}
              />
              <Button
                disabled={authLoading}
                type="submit"
                sx={{ marginTop: '15px' }}
                variant="contained"
              >
                Sign Up
                {authLoading && (
                  <CircularProgress
                    style={{ width: '20px', height: '20px', marginLeft: '8px' }}
                    color="inherit"
                  />
                )}
              </Button>
            </>
          )}

          {authError !== null && (
            <Alert
              sx={{
                marginTop: '15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              severity="error"
            >
              {authError}
            </Alert>
          )}
          {isAcSuccess && (
            <>
              <Alert
                sx={{
                  marginTop: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                severity="success"
              >
                Account create successfully. Please check your email - we have
                sent a email confirmation link
              </Alert>
            </>
          )}
        </Box>
      </form>
    </>
  );
};

export default SignUpForm;
