import React from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { purchasedProduct } from '../../Modules/ProductDetails/actions';
const PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });

const PayPal = ({ product }) => {
  const dispatch = useDispatch();
  const style = { layout: 'horizontal', tagline: 'false', shape: 'pill' };

  const createOrder = (data, actions) => {
    const transactionId = uuidv4();
    const createOrderPayload = {
      purchase_units: [
        {
          reference_id: product.productId,
          amount: {
            value: +product.price
          },
          payee: { email: 'payment@thriftzee.com' },
          // description: product.details,
          custom_id: transactionId
        }
      ]
    };
    return actions.order.create(createOrderPayload);
  };

  const onApprove = (data, actions) => {
    const captureOrderHandler = details => {
      dispatch(
        purchasedProduct({
          productId: product.productId,
          storeId: product.storeId,
          externalTxnId: details.id
        })
      );
    };
    return actions.order.capture().then(captureOrderHandler);
  };

  return (
    <PayPalButton
      createOrder={(data, actions) => createOrder(data, actions)}
      onApprove={(data, actions) => onApprove(data, actions)}
      style={style}
    />
  );
};

export default PayPal;
