import React from 'react';
import { Container, Typography } from '@mui/material';

const CommonHeader = ({ children }) => {
  return (
    <div style={{ backgroundColor: '#FF5943', padding: '40px 0 20px 0' }}>
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          color="white"
          letterSpacing="-0.333333px"
          sx={{
            fontWeight: { xs: 700, md: 800 },
            fontSize: { xs: '20px', md: '36px' },
            lineHeight: { xs: '23.44px', md: '42px' }
          }}
        >
          {children}
        </Typography>
      </Container>
    </div>
  );
};

export default CommonHeader;
