import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { setProductDetails } from '../../ProductDetails/reducer';
import soldIcon from '../../../resources/Sold.svg';
import closedIcon from '../../../resources/Closed.svg';
import cancelledIcon from '../../../resources/cancelled.svg';
// import lock from '../../../configs/authLock';
import Cards from './ProductListCard';
import './ProductCards.css';
import { productTypes } from '../../../variables';
import { auctionStatuses } from '../../../variables';

const ProductCards = ({ products, handleQuickBuy, handleQuickBid }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [productsList, setProductsList] = useState([]);
  const { isLoggedIn } = useSelector(state => state.loginReducer);

  useEffect(() => {
    setProductsList(products);
    if (!isLoggedIn) {
      // lock.hide();
    }
  }, [products, isLoggedIn, dispatch]);

  const handleClick = product => {
    const productName = product.productName
      .trim()
      .replace(/ /g, '-')
      .toLowerCase();
    dispatch(setProductDetails(product));
    navigate(`/product/${product.type}/${product.productId}/${productName}`);
  };
  const statusImg = product => {
    if (product.sold) return soldIcon;
    if (product.auctionStatus === auctionStatuses.CANCELLED) {
      return cancelledIcon;
    }
    if (
      product.type === productTypes.AUCTION &&
      product.endDate < new Date().getTime()
    ) {
      return closedIcon;
    }

    return null;
  };

  return (
    <Grid container spacing={3} className="product-cards-container">
      {productsList.map((product, i) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={4}
          key={i}
          style={{ borderRadius: '15px' }}
        >
          <Cards
            product={product}
            statusImg={statusImg(product)}
            handleClick={handleClick}
            handleQuickBid={handleQuickBid}
            handleQuickBuy={handleQuickBuy}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductCards;
