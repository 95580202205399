import { sortingCriteria } from '../../variables';

export const SortFunction = (value, products) => {
  let preSortedProducts = products.map(product =>
    product.type === 'buy'
      ? { ...product, price: parseInt(product.price) }
      : { ...product, price: parseInt(product.lastBid) }
  );
  switch (value) {
    case sortingCriteria.DATE_ASC:
      return preSortedProducts.sort((a, b) => a.createdOn - b.createdOn);

    case sortingCriteria.DATE_DESC:
      return preSortedProducts.sort((a, b) => b.createdOn - a.createdOn);

    case sortingCriteria.PRICE_ASC:
      return preSortedProducts.sort((a, b) => a.price - b.price);

    case sortingCriteria.PRICE_DESC:
      return preSortedProducts.sort((a, b) => b.price - a.price);

    default:
      return preSortedProducts;
  }
};
