import React from 'react';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './Location.css';

const CurrentLocation = () => {
  const { selectedStores, commonLocations } = useSelector(
    state => state.storeReducer
  );
  const navigate = useNavigate();
  function customJoinComma(arr, s1, s2) {
    return arr
      .slice(0, -1)
      .join(s1)
      .concat(arr.length > 1 ? s2 : '', arr.slice(-1));
  }
  if (selectedStores.length === 0) navigate('/home');
  return (
    <div className="location-change">
      <div className="location-icon">
        <LocationOnSharpIcon sx={{ color: '#FF5943' }} />
      </div>

      <div>
        {selectedStores.length > 1 ? (
          // <span className="location">{commonLocations}</span>

          commonLocations !== '' ? (
            <span className="location">{commonLocations}</span>
          ) : (
            customJoinComma(
              selectedStores.map(stores => stores.name),
              ', ',
              ' , '
            )
          )
        ) : (
          <span className="location">{selectedStores[0]?.address}</span>
        )}
        <span className="divider-change-location">|</span>
        <span
          className="change-location-style"
          onClick={() => navigate('/home')}
        >
          Change location
        </span>
      </div>
    </div>
  );
};

export default CurrentLocation;
