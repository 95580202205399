import {
  Box,
  Grid,
  // Stack,
  Container
  //IconButton
} from '@mui/material';
// import {
//   FacebookOutlined,
//   Twitter,
//   LinkedIn,
//   Instagram
// } from '@mui/icons-material';
import { Link } from 'react-router-dom';
const FooterAuth = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        left: '0px',
        bottom: '0px',
        height: '50px',
        width: '100%',
        bgcolor: 'secondary.main',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999
      }}
    >
      <Container maxWidth="lg">
      <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center" spacing={0.5}>
              <Grid item sx={{color: 'white'}}> &copy; {new Date().getFullYear()} thriftzee.com </Grid>
              <Grid item>
                <Link
                  to="/privacy"
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  {' '}
                  <span className="footer-privacy-color">Privacy Policy</span>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        {/* <Grid item>
            <Stack direction="row" spacing={1}>
              <IconButton aria-label="facebook">
                <FacebookOutlined sx={{ color: 'white' }} />
              </IconButton>
              <IconButton aria-label="twitter">
                <Twitter sx={{ color: 'white' }} />
              </IconButton>
              <IconButton aria-label="linkedin">
                <LinkedIn sx={{ color: 'white' }} />
              </IconButton>
              <IconButton aria-label="instagram">
                <Instagram sx={{ color: 'white' }} />
              </IconButton>
            </Stack>
          </Grid> */}
      </Container>
    </Box>
  );
};

export default FooterAuth;
