import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmptyMessage from '../../../Components/emptyMessage/EmptyMessage';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import StyledButton from '../../../Components/StyledButton';
import {
  Grid,
  Button,
  Box,
  Typography,
  Container,
  useMediaQuery,
  Tooltip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getCustomerBidHistory } from '../action';
import PayPal from './Paypal';
import CircularProgressLoader from '../../../Components/CircularProgressLoader';
import './HistoryComponents.css';
import { useNavigate } from 'react-router-dom';
import { setSelectedItemsFilter } from '../../ProductLists/reducer';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const getDate = fullDate => {
  const inputDate = new Date(fullDate);
  const month = inputDate.getMonth();
  const date = inputDate.getDate();
  const year = inputDate.getFullYear();
  return monthNames[month] + ' ' + date + ' ' + year;
};

const HistoryComponent = ({ handleQuickBid }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { bids, fetchBidStatus } = useSelector(
    state => state.userHistoryReducer
  );
  const { selectedStores } = useSelector(state => state.storeReducer);
  const [showPaypalBtn, setShowPaypalBtn] = useState(true);
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(getCustomerBidHistory());
  }, [dispatch]);

  const nameLength = () => {
    if (smScreen) return 17;
    if (mdScreen) return 25;
    else return 100;
  };

  const  handleClick=()=>{
    if (selectedStores.length > 0) {
      dispatch(setSelectedItemsFilter(['available', 'auction']));
      navigate('/auction');
    } else {
       navigate('/home',{state: {navigateTo :'/auction'}});
    }
  }

  return (
    <>
      <div style={{ backgroundColor: '#FF5943', padding: '40px 0 20px 0' }}>
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            color="white"
            letterSpacing="-0.333333px"
            sx={{
              fontWeight: { xs: 700, md: 800 },
              fontSize: { xs: '20px', md: '36px' },
              lineHeight: { xs: '23.44px', md: '42px' }
            }}
          >
            Bid History
          </Typography>
        </Container>
      </div>
      <div className={mdScreen ? 'bid-history-mb' : 'bid-history-lg'}>
        <Container
          maxWidth="lg"
          sx={{ py: 2.5 }}
          className="min-height-on-fetching"
        >
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '10px',
              padding: '10px'
            }}
          >
            {fetchBidStatus === 'pending' && <CircularProgressLoader />}
            {fetchBidStatus === 'error' && (
              <div
                style={{ textAlign: 'center', padding: '10px', color: 'red' }}
              >
                Fetching Bid History Failed!!
              </div>
            )}
            {fetchBidStatus === 'success' && !bids.length ? (
              <EmptyMessage
                emptyIcon={
                  <ProductionQuantityLimitsIcon sx={{ fontSize: 50 }} />
                }
              >
                <Grid item xs={12}>
                  You have no Bid records!
                </Grid>
                <Grid item xs={12}>
                  Please click below to browse and bid on any products now!
                </Grid>
                <Grid item>
                  <StyledButton
                    color={{ xs: '#FF5943', md: '#193C46' }}
                    onClick={() => {
                      handleClick();
                    }
                    }
                  >
                    Bid Products
                  </StyledButton>
                </Grid>
              </EmptyMessage>
            ) : (
              <></>
            )}
            {fetchBidStatus === 'success' && bids.length ? (
              <Grid container p={3}>
                {bids.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12} my={2} mt={!index ? 2 : 4}>
                      <Grid container spacing={2}>
                        <Grid item className="bid-history-img-background">
                          <img
                            className="history-list-img"
                            src={item.image}
                            alt={item.productName}
                          />
                        </Grid>
                        <Grid
                          className="bid-test-grid"
                          item
                          flexGrow={1}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column'
                          }}
                        >
                          <Grid container justifyContent="space-between">
                            <Typography
                              variant="body1"
                              m={0}
                              fontSize="16px"
                              fontWeight="600"
                              lineHeight="30px"
                              letterSpacing="-0.33px"
                              className="bid-history-date"
                            >
                              Date: {getDate(item.date)}
                            </Typography>
                            <Typography
                              variant="body1"
                              m={0}
                              fontSize="20px"
                              fontWeight="600"
                              lineHeight="30px"
                              letterSpacing="-0.33px"
                              sx={{ display: { xs: 'none', sm: 'block' } }}
                            >
                              {item.paid ? (
                                <>
                                  Status:{' '}
                                  <span className="history-font-color">
                                    Paid
                                  </span>
                                </>
                              ) : (
                                <>
                                  Auction Status:{' '}
                                  <span className="history-font-color">
                                    {item.auctionStatus === 'open'
                                      ? 'Ongoing'
                                      : item.auctionStatus === 'closed'
                                      ? 'Closed'
                                      : 'Cancelled'}
                                  </span>
                                </>
                              )}
                            </Typography>
                          </Grid>
                          <Typography
                            variant="h6"
                            fontSize="30px"
                            fontWeight="600"
                            lineHeight="35px"
                            letterSpacing="-0.33px"
                            py={2}
                            className="bid-history-name"
                          >
                            <Tooltip
                              title={item.productName}
                              placement="top-start"
                            >
                              <span
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  navigate(
                                    `/product/auction/${item.productId}/${item.productName}`
                                  )
                                }
                              >
                                {item.productName.length < nameLength()
                                  ? item.productName
                                  : item.productName.substring(
                                      0,
                                      nameLength()
                                    ) + '...'}
                              </span>
                            </Tooltip>
                          </Typography>
                          <Box m={0}>
                            <Typography
                              variant="body1"
                              fontWeight="600"
                              fontSize="20px"
                              lineHeight="30px"
                              letterSpacing="-0.33px"
                              className="bid-history-store"
                            >
                              Shop:{' '}
                              <span className="history-font-color">
                                {item.storeName}
                              </span>
                            </Typography>
                            <Grid
                              container
                              spacing={2}
                              justifyContent="space-between"
                            >
                              <Grid item>
                                <Typography
                                  fontWeight="600"
                                  fontSize="20px"
                                  lineHeight="30px"
                                  letterSpacing="-0.33px"
                                  className="bid-history-store"
                                >
                                  Bid amount:{' '}
                                  <span className="history-font-color">
                                    ${item.bidAmount}
                                  </span>
                                </Typography>
                                <Typography
                                  fontWeight="600"
                                  fontSize="20px"
                                  lineHeight="30px"
                                  letterSpacing="-0.33px"
                                  className="bid-history-store"
                                >
                                  Bid Status:{' '}
                                  <span className="history-font-color">
                                    {item.bidStatus}
                                  </span>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  m={0}
                                  fontSize="16px"
                                  fontWeight="600"
                                  lineHeight="30px"
                                  letterSpacing="-0.33px"
                                  sx={{ display: { xs: 'block', sm: 'none' } }}
                                >
                                  {item.paid ? (
                                    <>
                                      Status:{' '}
                                      <span className="history-font-color">
                                        Paid
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      Auction Status:{' '}
                                      <span className="history-font-color">
                                        {item.auctionStatus === 'open'
                                          ? 'Ongoing'
                                          : item.auctionStatus === 'closed'
                                          ? 'Closed'
                                          : 'Cancelled'}
                                      </span>
                                    </>
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                sx={{
                                  display: { xs: 'none', sm: 'flex' },
                                  alignItems: 'flex-end'
                                }}
                              >
                                {item.isWinningBid &&
                                !item.paid &&
                                showPaypalBtn ? (
                                  <PayPal
                                    setShowPaypal={setShowPaypalBtn}
                                    product={item}
                                  />
                                ) : (
                                  <></>
                                )}
                                {item.canBid ? (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{
                                      borderRadius: '30px',
                                      textTransform: 'none',
                                      width: '145px',
                                      height: '50px',
                                      '&:hover': {
                                        backgroundColor: '#FF5943'
                                      },
                                      fontWeight: '600',
                                      fontSize: '20px',
                                      lineHeight: '30px',
                                      letterSpacing: '-0.333333px'
                                    }}
                                    onClick={() => handleQuickBid(item)}
                                  >
                                    Quick Bid
                                  </Button>
                                ) : (
                                  <></>
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                          <Grid
                            item
                            xs={6}
                            sx={{
                              display: {
                                xs:
                                  (item.isWinningBid &&
                                    !item.paid &&
                                    showPaypalBtn) ||
                                  item.canBid
                                    ? 'block'
                                    : 'none',
                                sm: 'none'
                              },
                              mt: 2
                            }}
                          >
                            {item.isWinningBid &&
                            !item.paid &&
                            showPaypalBtn ? (
                              <PayPal
                                setShowPaypal={setShowPaypalBtn}
                                product={item}
                              />
                            ) : (
                              <></>
                            )}
                            {item.canBid ? (
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                  borderRadius: '30px',
                                  textTransform: 'none',
                                  width: '145px',
                                  height: '50px',
                                  fontWeight: '600',
                                  fontSize: '20px',
                                  lineHeight: '30px',
                                  letterSpacing: '-0.333333px'
                                }}
                                onClick={() => handleQuickBid(item)}
                              >
                                Quick Bid
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Divider sx={{ mb: { xs: 1, md: 2 } }} /> */}
                    <hr
                      style={{
                        width: '100%',
                        borderTop: 'none'
                      }}
                    />
                  </React.Fragment>
                  // <HistoryProduct key={index} item={item} />
                ))}
              </Grid>
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

HistoryComponent.defaultProps = {
  history: []
};

export default HistoryComponent;
