import { createAsyncThunk } from '@reduxjs/toolkit';
import thriftzee from '../../services/thrift';
import { csrf } from '../../utils/csrf';

export const getBidHistory = createAsyncThunk(
  'products/fetchBidHistory',
  async (productId, { getState }) => {
    const {
      loginReducer: { token }
    } = getState();
    let isPublic = false;
    let bearerToken;

    if (!token) {
      const csrfToken = await csrf();
      bearerToken = csrfToken;
      isPublic = true;
    } else {
      bearerToken = token;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    };

    const { data } = isPublic
      ? await thriftzee.get(`/public/products/${productId}/bids`, config)
      : await thriftzee.get(`/products/${productId}/bids`, config);
    let bids = [...data.data];
    bids.sort((a, b) => parseInt(b.bidAmount) - parseInt(a.bidAmount));
    return bids;
  }
);

export const getCustomerBidHistory = createAsyncThunk(
  'customer/fetchBidHistory',
  async (_, { getState }) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getState().loginReducer.token}`
      }
    };
    const response = await thriftzee.get('/customer/bids', config);

    const productIds = [];
    const winningProductIds = [];
    const final = [];
    const bidHistory = response.data.data.sort((a, b) => {
      return a.date < b.date ? 1 : -1;
    });
    
      for (const product of bidHistory) {
        if (productIds.indexOf(product.productId) === -1) {
          if (
            product.auctionStatus === 'open' &&
            product.bidStatus === 'Outbidded' &&
            winningProductIds.indexOf(product.productId) === -1
          ) {
            product.canBid = true;
          }
        }

        if (product.bidStatus === 'Highest Bidder') {
          winningProductIds.push(product.productId);
        }

        productIds.push(product.productId);

        final.push(product);
      }
      return final;
  }
);
