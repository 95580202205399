import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import ModalWithClose from '../Components/ModalWithClose';
import { logout } from '../Modules/Login/reducer';
import { setRedirect } from '../utils/redirect';

const CheckSession = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const { authResult, isLoggedIn } = useSelector(state => state.loginReducer);
  const tokenExpireTime = authResult?.accessToken?.payload?.exp;

  useEffect(() => {
    if (isLoggedIn) {
      const isTokenExpired = tokenExpireTime * 1000 <= Date.now();
      if (isTokenExpired) {
        setModalOpen(true);
      }
    }
  }, [isLoggedIn, tokenExpireTime]);

  const handleClose = () => {
    setModalOpen(false);
    dispatch(logout());
    setRedirect(pathname);
    navigate('/login');
  };

  return (
    modalOpen && (
      <ModalWithClose open={modalOpen} handleClose={handleClose}>
        <div style={{ padding: '20px' }}>
          <Alert variant="standard" severity="error">
            Your session is expired please login again!
          </Alert>
        </div>
      </ModalWithClose>
    )
  );
};

export default CheckSession;
