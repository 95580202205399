import { useEffect } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ContactTextField from './ContactTextField';
import { submitContactInfo } from '../action';
import { ContactSchema } from './ContactSchema';
import './ContactForm.css';
import { fetchStatus } from '../../../variables';

export default function ContactForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({ resolver: yupResolver(ContactSchema), mode: "onBlur" });
  const dispatch = useDispatch();
  const { status } = useSelector(state => state.contactReducer);

  const onSubmit = data => {
    dispatch(submitContactInfo(data));
  };

  useEffect(() => {
    if (status === fetchStatus.SUCCESS) reset();
  }, [status, reset]);

  return (
    <Box className="contact-form">
      <Typography
        sx={{
          fontSize: '20px',
          fontWeight: '600',
          lineHeight: '21px',
          letterSpacing: '-0.33px',
          color: '#193C46',
          pb: 3,
          textAlign: { xs: 'center', md: 'left' }
        }}
      >
        Send us a message and we will get right back to you
      </Typography>
      <h2 className="required-message">
        <span className="contact-label-span">(*)</span> Fields are required
      </h2>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Grid container spacing={{ xs: 0, md: 2 }}>
              <Grid item xs={12} md={6}>
                <label htmlFor="firstname" className="contact-label">
                  First Name<span className="contact-label-span">*</span>
                </label>
                <ContactTextField
                  id="firstname"
                  error={errors.hasOwnProperty('firstName')}
                  helperText={errors?.firstName?.message}
                  {...register('firstName')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <label htmlFor="lastname" className="contact-label">
                  Last Name<span className="contact-label-span">*</span>
                </label>
                <ContactTextField
                  id="lastname"
                  error={errors.hasOwnProperty('lastName')}
                  helperText={errors?.lastName?.message}
                  {...register('lastName')}
                />
              </Grid>
            </Grid>
          </Box>
          <label htmlFor="email" className="contact-label">
            Email<span className="contact-label-span">*</span>
          </label>
          <ContactTextField
            id="email"
            error={errors.hasOwnProperty('email')}
            helperText={errors?.email?.message}
            {...register('email')}
          />
          <label htmlFor="phone" className="contact-label">
            Phone<span className="contact-label-span">*</span>
          </label>
          <ContactTextField
            id="phone"
            error={errors.hasOwnProperty('phone')}
            helperText={errors?.phone?.message}
            {...register('phone')}
          />
          <Box>
            <label htmlFor="message" className="contact-label">
              Message<span className="contact-label-span">*</span>
            </label>
            <TextField
              id="message"
              className="textarea"
              fullWidth
              sx={{ my: 1 }}
              multiline={true}
              rows={3}
              error={errors.hasOwnProperty('message')}
              helperText={errors?.message?.message}
              {...register('message')}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', md: 'start' }
            }}
          >
            <Button
              sx={{
                bgcolor: { xs: '#FF5943', md: '#193C46' },
                '&:hover': { bgcolor: '#FF5943' }
              }}
              variant="contained"
              className="submit"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}