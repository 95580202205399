import React from 'react';
import closedIcon from '../../../resources/Closed.svg';
import cancelledIcon from '../../../resources/cancelled.svg';
import { Grid, CardMedia } from '@mui/material';
import { useSelector } from 'react-redux';
import { auctionStatuses } from '../../../variables'; 

const AuctionProductInfo = ({ quickBid }) => {
  const {
    productDetails: { auctionStatus, image, productName, storeName, totalBids}
  } = useSelector(state => state.productDetailsReducer);
  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6} py={{ xs: 1, md: 4 }}>
          {auctionStatus === auctionStatuses.CLOSED && (
            <div className="product-details-icon-over-img">
              <img
                src={closedIcon}
                alt="closed"
                className="product-details-sold-icon"
              />
            </div>
          )}
           {auctionStatus === auctionStatuses.CANCELLED && (
            <div className="product-details-icon-over-img">
              <img
                src={cancelledIcon}
                alt="cancelled"
                className="product-details-sold-icon"
              />
            </div>
          )}
          <CardMedia
            className="product-details-img"
            component="img"
            sx={{ borderRadius: '10px', maxHeight: '450px' }}
            image={image}
            alt={productName}
            height="auto"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={4}>
            <Grid item container direction="column" xs={12}>
              <div className="numberOfBids-label-product-details">
                Number of Bids:{' '}
                <span className="numberOfBids-product-details">
                  {totalBids}
                </span>
              </div>
              <div className="product-owner">
                Shop/ owner:{' '}
                <span className="product-owner-name">{storeName}</span>
              </div>
            </Grid>
            <Grid item xs={12}>
              {quickBid}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AuctionProductInfo;
