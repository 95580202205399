const setQuickBid = () => {
  sessionStorage.setItem('quickBid', true);
};
const isNoBid = () => {
  return sessionStorage.getItem('quickBid') === null;
};

const removeBid = () => {
  sessionStorage.removeItem('quickBid');
};

export { setQuickBid, isNoBid, removeBid };
