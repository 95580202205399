import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  Backdrop,
  CircularProgress
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ContactCreds from './components/ContactCreds';
import ContactForm from './components/ContactForm';
import CommonModal from './components/CommonModal';
import SuccessMessage from './components/SuccessMessage';
import ErrorMessage from './components/ErrorMessage';
import CommonLayout from '../../Layouts/CommonLayout';
import { fetchStatus } from '../../variables';
import './ContactLayout.css';

export default function ContactCard() {
  const theme = useTheme();
  const [showSuccess, setShowSuccess] = useState(true);
  const [showError, setShowError] = useState(true);
  const { status } = useSelector(state => state.contactReducer);
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  document.title = 'Contact Thriftzee';
  return (
    <CommonLayout>
      <div className="contact-header-container">
        <Container maxWidth="lg" sx={{ pt: 4, pb: { xs: 1.5, md: 4 } }}>
          <Typography component="h1" className="contact-header">
            Contact
          </Typography>
        </Container>
      </div>
      <div className={mdScreen ? 'contact-mobile-bg ' : 'contact-desktop-bg'}>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={0}
            justifyContent="space-between"
            sx={{
              p: { xs: 1.5, md: 10 },
              mt: { xs: 2, md: 0 },
              bgcolor: 'white',
              borderRadius: '10px'
            }}
          >
            <Grid item md={7}>
              <Box>
                <ContactForm />
                {status === fetchStatus.PENDING && (
                  <Backdrop
                    sx={{
                      color: '#fff',
                      zIndex: theme => theme.zIndex.drawer + 1
                    }}
                    open={true}
                  >
                    <CircularProgress />
                  </Backdrop>
                )}
                {status === fetchStatus.SUCCESS && (
                  <CommonModal open={showSuccess}>
                    <SuccessMessage setShowSuccess={setShowSuccess} />
                  </CommonModal>
                )}
                {status === fetchStatus.ERROR && (
                  <CommonModal open={showError}>
                    <ErrorMessage setShowError={setShowError} />
                  </CommonModal>
                )}
                {/* {status === 'success' && (
                  <CommonModal open={showSuccess}>
                    <SuccessMessage handleClose={() => setShowSuccess(false)} />
                  </CommonModal>
                )} */}
              </Box>
            </Grid>
            <Grid item md={3.75}>
              <Box
                py={{
                  md: 1,
                  xs: 3
                }}
              >
                <ContactCreds />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </CommonLayout>
  );
}
