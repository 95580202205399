import React from 'react';
import Avatar from '@mui/material/Avatar';
import { Box, Divider, Typography } from '@mui/material';

function BidsItem({ name = 'User Name', time = '1 Hour', amount = '48.00' }) {
  return (
    <Box mt={0}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        {/* User Image and Info */}
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box
            sx={{
              display: 'flex',
              border: '1px solid #193C46',
              borderRadius: '2px',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Avatar
              sx={{
                bgcolor: 'transparent',
                color: '#193C46',
                fontWeight: '700',
                fontSize: '30px'
              }}
            >
              {name[0].toUpperCase()}
            </Avatar>
          </Box>

          {/* Name and Time */}
          <Box
            mx={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left'
            }}
          >
            <Typography
              fontSize="16px"
              lineHeight="22px"
              letterSpacing="-0.33px"
              mb={'5px'}
            >
              {name}
            </Typography>

            <Typography
              color="primary"
              fontSize="16px"
              lineHeight="22px"
              letterSpacing="-0.33px"
            >
              {time}
            </Typography>
          </Box>
        </Box>

        {/* Biding Amount */}
        <Box mr={2.5}>
          <Typography
            fontWeight={600}
            fontSize="16px"
            lineHeight="22px"
            letterSpacing="-0.33px"
          >
            ${amount}
          </Typography>
        </Box>
      </Box>
      <Box mt={2}>
        <Divider
          sx={{
            borderBottomWidth: '1.6px',
            width: '100%'
          }}
        />
      </Box>
    </Box>
  );
}

export default BidsItem;
