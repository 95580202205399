import { forwardRef } from 'react';
import { TextField } from '@mui/material';

const ContactTextField = forwardRef((props, ref) => (
  <TextField
    inputRef={ref}
    sx={{
      my: 1
    }}
    fullWidth
    className="contactTextField"
    variant="outlined"
    label={props.label}
    {...props}
  />
));

export default ContactTextField;
