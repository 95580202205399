import { createAsyncThunk } from '@reduxjs/toolkit';
import { csrf } from '../../../utils/csrf';
import thriftzee from '../../../services/thrift';

export const getDefaultFeatureProducts = createAsyncThunk(
  'landingPage/fetchDefaultFeatureProduct',
  async (latlon, { getState, dispatch }) => {
    let bearerToken;
    const csrfToken = await csrf();
    bearerToken = csrfToken;

    const config = {
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    };

    const { data } = await thriftzee.post(
      '/public/products/search?featured=true',
      {},
      config
    );
    return data.data;
  }
);

export const getFeatureProducts = createAsyncThunk(
  'landingPage/fetchFeatureProduct',
  async (storeIds, { getState }) => {
    let bearerToken;

    const csrfToken = await csrf();
    bearerToken = csrfToken;

    const config = {
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    };
    const {
      userPositionReducer: {
        position: { latitude, longitude }
      }
    } = getState();

    const { data } = await thriftzee.post(
      `/public/products/search?featured=true&lat=${latitude}&lng=${longitude}`,
      {},
      config
    );

    return data.data;
  }
);
