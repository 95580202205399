import React, { useState, useEffect, useCallback } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { featureContent } from '../redux/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus, loginUrl } from '../../../variables';
import ModalWithClose from '../../../Components/ModalWithClose';
import QuickBuy from '../../../Components/quickBuy';
import QuickBid from '../../../Components/QuickBid';
import QuickBidModal from '../../../Components/QuickBid/ModalWithClose';
import CommonModal from '../../../Components/CommonModal';
import AuctionBuySuccess from '../../../Components/productDetails/AuctionBuySuccess';
import {
  setProduct,
  getProduct,
  removeProduct
} from '../../../utils/savedProduct';
import { setRedirect } from '../../../utils/redirect';
import First from './First';
import Second from './Second';
import Third from './Third';
import { setQuickBid, isNoBid, removeBid } from '../../../utils/quickBidPopUp';
import { setQuickBuy, isNoBuy, removeBuy } from '../../../utils/quickBuyPopUp';
import CommonSmallModal from '../../../Components/productDetails/CommonSmallModal';
import AuctionIsOver from '../../../Components/productDetails/AuctionIsOver';
import { getFeatureProducts } from '../redux/action';
import { setBidStatus } from '../../bidHistory/reducer';
import { setPurchaseStatus } from '../../ProductDetails/reducer';

const Advertise = () => {
  const dispatch = useDispatch();
  const [showQuickBuy, setshowQuickBuy] = useState(false);
  const [showQuickBid, setshowQuickBid] = useState(false);
  const [showSuccess, setShowSuccess] = useState(true);
  const [showBidSuccess, setShowBidSuccess] = useState(true);
  const [productDetails, setProductDetails] = useState({});
  const [auctionOver, setAucitonOver] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const { pathname } = location;
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {
    featuredDetails: { products }
  } = useSelector(featureContent);

  const { placeBidStatus } = useSelector(state => state.bidHistoryReducer);
  const { purchaseProductStatus } = useSelector(
    state => state.productDetailsReducer
  );
  const { isLoggedIn } = useSelector(state => state.loginReducer);
  const loginReducer = useSelector(state => state.loginReducer);

  const handleQuickBuy = useCallback(
    product => {
      if (!isLoggedIn) {
        setRedirect(pathname);
        setProduct(product);
        setQuickBuy();
        navigate(loginUrl);
      }
      setProductDetails(product);
      setshowQuickBuy(true);
      // console.log(product);
    },
    [isLoggedIn, navigate, pathname]
  );

  const handleQuickBid = useCallback(
    product => {
      if (!isLoggedIn) {
        setRedirect(pathname);
        setProduct(product);
        setQuickBid();
        navigate('/login');
      }
      setProductDetails(product);
      setshowQuickBid(true);
      // console.log(isLoggedIn);
      // console.log(product);
    },
    [isLoggedIn, navigate, pathname]
  );

  const handleOkay = () => {
    setAucitonOver(false);
    dispatch(getFeatureProducts());
  };

  useEffect(() => {
    if (!isNoBid()) {
      setTimeout(() => {
        removeBid();
      }, 5000);
      const product = getProduct();
      setTimeout(() => {
        removeProduct();
      }, 5000);
      if (product.endDate < new Date().getTime()) {
        return setAucitonOver(true);
      }
      handleQuickBid(product);
    }
  }, [handleQuickBid]);
  useEffect(() => {
    if (!isNoBuy()) {
      removeBuy();
      const product = getProduct();
      setTimeout(() => {
        removeProduct();
      }, 5000);

      handleQuickBuy(product);
    }
  }, [handleQuickBuy]);

  const handleBidSuccessModalClose = () => {
    dispatch(setBidStatus(fetchStatus.IDLE));
    setShowBidSuccess(false);
    setshowQuickBid(false);
    dispatch(getFeatureProducts());
  };

  const handleBuySuccessModalClose = () => {
    dispatch(setPurchaseStatus(fetchStatus.IDLE));
    setShowSuccess(false);
    dispatch(getFeatureProducts());
  };

  if (!loginReducer.isLoggedIn) {
    removeBid();
    removeBuy();
  }

  return (
    <>
      <div className="flex flex-col gap-8">
        <div
          className={`flex flex-col ${mdScreen ? 'gap-1' : 'gap-8'} `}
          style={{ backgroundColor: mdScreen ? '' : '#f6f6f6' }}
        >
          {products.length >= 1 && (
            <First
              product={products[0]}
              handleQuickBuy={handleQuickBuy}
              handleQuickBid={handleQuickBid}
            />
          )}
          {products.length >= 2 && (
            <Second
              product1={products[1]}
              product2={products.length >= 3 && products[2]}
              handleQuickBuy={handleQuickBuy}
              handleQuickBid={handleQuickBid}
            />
          )}
          {products.length >= 4 && (
            <Third
              product={products[3]}
              handleQuickBuy={handleQuickBuy}
              handleQuickBid={handleQuickBid}
            />
          )}
        </div>
      </div>
      <ModalWithClose
        open={showQuickBuy && purchaseProductStatus !== 'success'}
        handleClose={() => setshowQuickBuy(false)}
      >
        <QuickBuy product={productDetails} />
      </ModalWithClose>
      {purchaseProductStatus === 'success' && (
        <CommonModal
          open={showSuccess}
          handleClick={handleBuySuccessModalClose}
        >
          <AuctionBuySuccess
            type="buy"
            handleClose={() => setShowSuccess(false)}
          />
        </CommonModal>
      )}
      <QuickBidModal
        open={showQuickBid && placeBidStatus !== 'success'}
        handleClose={() => setshowQuickBid(false)}
      >
        <QuickBid
          product={productDetails}
          setAuctionOver={setAucitonOver}
          setshowQuickBid={setshowQuickBid}
        />
      </QuickBidModal>
      <CommonSmallModal open={auctionOver}>
        <AuctionIsOver handleOkay={handleOkay} />
      </CommonSmallModal>
      {placeBidStatus === 'success' && (
        <CommonModal
          open={showBidSuccess}
          handleClick={handleBidSuccessModalClose}
        >
          <AuctionBuySuccess
            type="auction"
            handleClose={() => setShowBidSuccess(false)}
          />
        </CommonModal>
      )}
    </>
  );
};

export default Advertise;
