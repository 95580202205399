import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Icon1 from '../../../resources/Group-3.svg';
import Icon2 from '../../../resources/Group-4.svg';
import Icon3 from '../../../resources/Group-5.svg';
import StyledButton from '../../../Components/StyledButton';
import { useSelector } from 'react-redux';

const Instruction = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { selectedStores } = useSelector(state => state.storeReducer);
  return (
    <>
      <div className="instruction-first-header">How Thriftzee Works</div>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        py={4}
      >
        <Grid item xs={12} md={4} textAlign="center">
          <div className="instruction-icon">
            <img src={Icon2} alt="" />
          </div>
          <div className="instruction-sub-header-text">
            Sign Up / Registration
          </div>
          <div className="instruction-paragraph">
            Swift and easy registration. Just enter your email, password and
            phone number or use your social media for instant registration.
            Already a member? Just enter your email and password to login or via
            your social media account
          </div>
        </Grid>
        <Grid item xs={12} md={4} textAlign="center">
          <div className="instruction-icon">
            <img src={Icon3} alt="" />
          </div>
          <div className="instruction-sub-header-text">
            Browse through products
          </div>
          <div className="instruction-paragraph">
            Shop among a wide variety of products available for Auction or
            Purchase from your desired shops. Have some specific products on
            your mind? Use our search bar and filters to find your similar
            products at your disposal.
          </div>
        </Grid>
        <Grid item xs={12} md={4} textAlign="center">
          <div className="instruction-icon">
            <img src={Icon1} alt="" />
          </div>
          <div className="instruction-sub-header-text">
            Instantly bid and buy
          </div>
          <div className="instruction-paragraph">
            Products are available for both Auction and Purchase. Simply select
            your preferred product and conveniently bid or purchase using our
            secured payment method. Also, make use of our Quick Bid and Quick
            Buy options for faster and smoother transactions.
          </div>
        </Grid>
      </Grid>
      <div
        style={{
          paddingTop: mdScreen ? '0px' : '10px',
          paddingBottom: mdScreen ? '0px' : '60px',
          textAlign: 'center'
        }}
      >
        <StyledButton
          className="instruction-get-started-btn"
          color={{ xs: '#FF5943', md: '#193C46' }}
          onClick={() =>
            !selectedStores.length ? navigate('/home') : navigate('/products')
          }
        >
          Get Started Now
        </StyledButton>
      </div>
    </>
  );
};

export default Instruction;
