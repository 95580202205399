import * as yup from 'yup';
const PhonePatternRegex =
  '\\D*([2-9]\\d{2})(\\D*)([2-9]\\d{2})(\\D*)(\\d{4})\\D*';

export const ContactSchema = yup.object({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email().required('Email is required'),
  phone: yup
    .string()
    .matches(new RegExp(PhonePatternRegex), 'Phone number is not valid')
    .required('Phone Number is required'),
  message: yup.string().required('Message is required')
});
