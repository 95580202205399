import React from 'react';
import { Grid } from '@mui/material';
import HeroImg from '../../../../resources/hero.png';

const RightSection = () => {
  return (
    <>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <img className="hero-img" src={HeroImg} alt="hero-img" />
        </Grid>
      </Grid>
    </>
  );
};

export default RightSection;
