import { Box, Modal, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: 'auto', md: '75%' },
  minWidth: 300,
  maxWidth: '1000px',
  bgcolor: 'white',
  p: 1,

  borderRadius: '10px',
  outline: 'none',
  '&:focus': {
    outline: 'none'
  },
  '&:hover': {
    outline: 'none'
  }
};

const CommonModal = ({ open, handleClick, children }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="common-modal-close-icon" onClick={handleClick}>
          <IconButton>
            <ClearIcon sx={{ fontSize: { xs: '34px', md: '42px' } }} />
          </IconButton>
        </div>
        <div className="common-modal-content">{children}</div>
      </Box>
    </Modal>
  );
};

export default CommonModal;
