import React from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { purchaseBidProduct } from '../../ProductDetails/actions';
const PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });

const style = { layout: 'horizontal', tagline: 'false', shape: 'pill' };

const PayPal = ({ setShowPaypal, product }) => {
  const dispatch = useDispatch();
  const createOrder = (data, actions) => {
    const transactionId = uuidv4();
    const createOrderPayload = {
      purchase_units: [
        {
          reference_id: product.productId,
          amount: {
            value: product.bidAmount
          },
          payee: { email: 'payment@thriftzee.com' },
          custom_id: transactionId
        }
      ]
    };
    return actions.order.create(createOrderPayload);
  };

  const onApprove = (data, actions) => {
    const captureOrderHandler = details => {
      dispatch(
        purchaseBidProduct({
          bidId: product.id,
          productId: product.productId,
          storeId: product.storeId,
          externalTxnId: details.id,
          txnAmount: product.bidAmount
        })
      );
      setShowPaypal(false);
    };
    return actions.order.capture().then(captureOrderHandler);
  };

  const onCancel = () => {
    // console.log('cancel');
  };

  return (
    <PayPalButton
      createOrder={(data, actions) => createOrder(data, actions)}
      onApprove={(data, actions) => onApprove(data, actions)}
      onCancel={onCancel}
      style={style}
    />
  );
};
export default PayPal;
