import React from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import BidsItem from './BidsItem';
import { bidderPage } from '../../../variables';

const BidderListPagination = ({ count }) => {
  const { bids } = useSelector(state => state.bidHistoryReducer);
  const bidder = [...bids].slice((count-1)*bidderPage,(count-1)*bidderPage+bidderPage)
  return (
    <div>
      <Grid container>
        {bidder.map((bid, index) => (
          <Grid item xs={12} key={index}>
            <BidsItem
              name={bid.userName}
              time={moment(bid.date).fromNow()}
              amount={bid.bidAmount}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default BidderListPagination;
