import React from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { purchasedProduct } from '../actions';
const PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });

const PayPal = ({ setShowPaypal, product, setTrasactionId, shape }) => {
  const dispatch = useDispatch();
  const createOrder = (data, actions) => {
    const transactionId = uuidv4();
    const createOrderPayload = {
      purchase_units: [
        {
          reference_id: product.productId || '22335446',
          amount: {
            value: +product.price || 10
          },
          payee: { email: 'payment@thriftzee.com' },
          // description: product.details || 'Test product',
          custom_id: transactionId
        }
      ]
    };
    return actions.order.create(createOrderPayload);
  };

  const style = {
    layout: 'horizontal',
    tagline: 'false',
    shape: shape || 'rect'
  };

  const onApprove = (data, actions) => {
    const captureOrderHandler = details => {
      // const payerName = details.payer.name.given_name;
      console.log('Transaction completed!', details);
      dispatch(
        purchasedProduct({
          productId: product.productId,
          storeId: product.storeId,
          externalTxnId: details.id
        })
      );
      setTrasactionId(details.id);
      setShowPaypal(false);
    };
    return actions.order.capture().then(captureOrderHandler);
  };

  const onCancel = () => {
    // console.log('cancel');
  };

  return (
    <PayPalButton
      createOrder={(data, actions) => createOrder(data, actions)}
      onApprove={(data, actions) => onApprove(data, actions)}
      onCancel={onCancel}
      style={style}
    />
  );
};
export default PayPal;
