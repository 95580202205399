import { Grid, Container } from '@mui/material';
const EmptyMessage = ({ children, emptyIcon }) => {
  return (
    <>
      <Container maxWidth="lg">
        <Grid
          container
          sx={{ minHeight: '300px' }}
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            {emptyIcon}
          </Grid>
          {children}
        </Grid>
      </Container>
    </>
  );
};
export default EmptyMessage;
