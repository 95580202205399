import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { setBidStatus } from '../bidHistory/reducer';
import { getBidHistory } from '../bidHistory/action';
import { getProductDetails } from './actions';
import CommonModal from '../../Components/CommonModal';
import AuctionBuySuccess from '../../Components/productDetails/AuctionBuySuccess';
import ProductBidderList from './components/ProductBidderList';
import AuctionProductInfo from './components/AuctionProductInfo';
import ProductPlaceBid from './components/ProductPlaceBid';
import CommonSmallModal from '../../Components/productDetails/CommonSmallModal';
import AuctionIsOver from '../../Components/productDetails/AuctionIsOver';
import { setProductDetailsStatus } from './reducer';

import './components/AuctionProduct.css';
import { fetchStatus } from '../../variables';

const AuctionProductDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [auctionOver, setAucitonOver] = useState(false);
  const productDetails =
    useSelector(state => state.productDetailsReducer).productDetails || {};
  const { placeBidStatus } = useSelector(state => state.bidHistoryReducer);

  const handleOkay = () => {
    setAucitonOver(false);
    dispatch(getProductDetails(id));
    dispatch(getBidHistory(id));
  };

  useEffect(() => {
    if (placeBidStatus === 'success') {
      dispatch(getProductDetails(id));
      dispatch(getBidHistory(id));
      setShow(false);
      setShowSuccess(true);
      dispatch(setBidStatus('idle'));
    }
  }, [placeBidStatus, dispatch, id]);

  useEffect(() => {
    dispatch(getBidHistory(id));
    return () => {
      dispatch(setBidStatus('idle'));
      dispatch(setProductDetailsStatus('idle'));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (show) {
      dispatch(getProductDetails(id));
      dispatch(getBidHistory(id));
    }
  }, [show, dispatch, id]);

  const handleModalClose = () => {
    dispatch(setBidStatus(fetchStatus.IDLE));
    setShowSuccess(false);
  };

  return (
    <>
      <AuctionProductInfo
        quickBid={
          <ProductPlaceBid
            product={productDetails}
            setAuctionOver={setAucitonOver}
          />
        }
      />

      <Grid container py={5} spacing={5}>
        <Grid item xs={12} md={6}>
          <div className="product-details-description-header">Details</div>
          <div className="product-details-description auction-product-details">
            {productDetails.details}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div
            style={{
              width: '100%',
              alignItems: 'center',
              display: 'flex',
              paddingBottom: '25px'
            }}
          >
            <div className="product-details-bids">Bids</div>
            <div className="divider"></div>
          </div>
          <ProductBidderList />
        </Grid>
      </Grid>
      <CommonModal open={showSuccess} handleClick={handleModalClose}>
        <AuctionBuySuccess
          type="auction"
          handleClose={() => setShowSuccess(false)}
        />
      </CommonModal>
      <CommonSmallModal open={auctionOver}>
        <AuctionIsOver handleOkay={handleOkay} />
      </CommonSmallModal>
    </>
  );
};

export default AuctionProductDetails;
