import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Box,
  Typography,
  Divider,
  Container,
  useMediaQuery,
  Tooltip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getPurchaseHistory } from '../action';
import CircularProgressLoader from '../../../Components/CircularProgressLoader';
import './HistoryComponents.css';
import { useNavigate } from 'react-router-dom';
import EmptyMessage from '../../../Components/emptyMessage/EmptyMessage';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import StyledButton from '../../../Components/StyledButton';
import { setSelectedItemsFilter } from '../../ProductLists/reducer';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const getDate = fullDate => {
  const inputDate = new Date(fullDate);
  const month = inputDate.getMonth();
  const date = inputDate.getDate();
  const year = inputDate.getFullYear();
  return monthNames[month] + ' ' + date + ' ' + year;
};

const HistoryComponent = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { purchases, purchaseStatus } = useSelector(
    state => state.userHistoryReducer
  );
  const { selectedStores } = useSelector(state => state.storeReducer);

  useEffect(() => {
    dispatch(getPurchaseHistory());
  }, [dispatch]);

  const nameLength = () => {
    if (smScreen) return 17;
    if (mdScreen) return 25;
    else return 100;
  };
  const handleClick = () => {
    if (selectedStores.length > 0) {
      dispatch(setSelectedItemsFilter(['available', 'buy']));
      navigate('/buy');
    } else {
       navigate('/home',{state: {navigateTo :'/buy'}});
    }
  };
  return (
    <>
      <div style={{ backgroundColor: '#FF5943', padding: '20px 0 0 0' }}>
        <Container maxWidth="lg" sx={{ py: 3 }}>
          <Typography
            variant="h3"
            color="white"
            letterSpacing="-0.333333px"
            sx={{
              fontWeight: { xs: 700, md: 800 },
              fontSize: { xs: '20px', md: '36px' },
              lineHeight: { xs: '23.44px', md: '42px' }
            }}
          >
            Purchase History
          </Typography>
        </Container>
      </div>

      <div className={mdScreen ? 'bid-history-mb' : 'bid-history-lg'}>
        <Container
          maxWidth="lg"
          sx={{ py: 1.6 }}
          className="min-height-on-fetching"
        >
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '10px',
              padding: '10px'
            }}
          >
            {purchaseStatus === 'pending' ? (
              <CircularProgressLoader />
            ) : purchaseStatus === 'success' && !purchases.length ? (
              <EmptyMessage
                emptyIcon={
                  <ProductionQuantityLimitsIcon sx={{ fontSize: 50 }} />
                }
              >
                <Grid item xs={12}>
                  You have no Purchase records!
                </Grid>
                <Grid item xs={12}>
                  Please click below to browse and buy any products now!
                </Grid>
                <Grid item>
                  <StyledButton
                    color={{ xs: '#FF5943', md: '#193C46' }}
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    Buy Products
                  </StyledButton>
                </Grid>
              </EmptyMessage>
            ) : (
              <Grid container p={2} flexDirection="column">
                {[...purchases]
                  .sort((a, b) => b.date - a.date)
                  .map((item, index) => (
                    <React.Fragment key={index}>
                      <Grid item my={2}>
                        <Grid container spacing={2}>
                          <Grid container className="history-img-background">
                            <img
                              className="history-list-img"
                              src={item.image}
                              alt={item.productName}
                            />
                          </Grid>
                          <Grid
                            className="test-grid"
                            item
                            flexGrow={1}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column'
                            }}
                          >
                            <Typography
                              variant="body1"
                              m={0}
                              fontSize="16px"
                              fontWeight="600"
                              lineHeight="30px"
                              letterSpacing="-0.33px"
                              className="bid-history-date"
                            >
                              Date: {getDate(item.date)}
                            </Typography>
                            <Typography
                              variant="h6"
                              fontSize="30px"
                              fontWeight="600"
                              lineHeight="35px"
                              letterSpacing="-0.33px"
                              py={{ xs: 1, md: 2 }}
                              className="bid-history-name"
                            >
                              <Tooltip
                                title={item.productName}
                                placement="top-start"
                              >
                                <span
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    navigate(
                                      `/product/auction/${item.productId}/${item.productName}`
                                    )
                                  }
                                >
                                  {item.productName.length < nameLength()
                                    ? item.productName
                                    : item.productName.substring(
                                        0,
                                        nameLength()
                                      ) + '...'}
                                </span>
                              </Tooltip>
                            </Typography>
                            <Box m={0}>
                              <Typography
                                variant="body1"
                                fontWeight="600"
                                fontSize="20px"
                                lineHeight="30px"
                                letterSpacing="-0.33px"
                                className="bid-history-store"
                              >
                                Shop:{' '}
                                <span className="history-font-color">
                                  {item.storeName}
                                </span>
                              </Typography>
                              <Grid
                                container
                                spacing={2}
                                justifyContent="space-between"
                              >
                                <Grid item>
                                  <Typography
                                    fontWeight="600"
                                    fontSize="20px"
                                    lineHeight="30px"
                                    letterSpacing="-0.33px"
                                    className="bid-history-store"
                                  >
                                    Price:{' '}
                                    <span className="history-font-color">
                                      ${item.price}
                                    </span>
                                  </Typography>
                                  <Typography
                                    fontWeight="600"
                                    fontSize="20px"
                                    lineHeight="30px"
                                    letterSpacing="-0.33px"
                                    className="bid-history-store"
                                  >
                                    Transaction ID:{' '}
                                    <span className="history-font-color">
                                      {item.externalTxnId}
                                    </span>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mb: { xs: 1, md: 2 } }} />
                    </React.Fragment>
                  ))}
              </Grid>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default HistoryComponent;
