import React from 'react';
import { Box, Typography } from '@mui/material';
import mailIcon from '../assets/mail.svg';

export default function ContactCreds() {
  return (
    <Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 1
          }}
        >
          <Box
            mx={2}
            sx={{ ml: { xs: 0 }, borderRadius: '6px' }}
            component={'img'}
            src={mailIcon}
            alt="Mobile Icon"
          ></Box>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '14px', sm: '16px' }
            }}
          >
            Email: <span style={{ color: '#FF5943' }}> info@thriftzee.com</span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
