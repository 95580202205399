import { Grid, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { HighlightOff } from '@mui/icons-material';

const StyledButton = styled(Button)(({ theme }) => ({
  height: '50px',
  textTransform: 'none',
  borderRadius: '30px',
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '23px',
  letterSpacing: '-0.333333px',
  width: '100px'
}));

const AuctionIsOver = ({ handleOkay }) => {
  return (
    <Grid container flexDirection="column" spacing={3} alignItems="center">
      <Grid item container flexWrap="nowrap" xs={12} md={8} lg={6}>
        <Grid
          item
          sx={{
            mr: { sx: 0, md: '10px' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <HighlightOff sx={{ color: '#FF0000', fontSize: '70px' }} />
        </Grid>
        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography component="h1" fontSize="18px" fontWeight="bold">
            Auction is already over!
          </Typography>
        </Grid>
      </Grid>
      <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
        <StyledButton
          variant="contained"
          color="secondary"
          sx={{ mb: { xs: '10px', md: 0 } }}
          onClick={handleOkay}
        >
          Okay
        </StyledButton>
      </Grid>
    </Grid>
  );
};

export default AuctionIsOver;
