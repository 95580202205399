import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import StyledButton from '../../../Components/StyledButton';
import BidCountDown from '../../ProductDetails/components/BidCountdown';
import { useNavigate } from 'react-router-dom';

const First = ({ product, handleQuickBid, handleQuickBuy }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div
      className={`flex justify-center items-center py-1 ${
        mdScreen ? 'flex-wrap ' : 'gap-8 landing-first-grid'
      }`}
    >
      <div
        className={`flex justify-center items-center   ${
          mdScreen ? 'landing-bg-1 fullWidth' : 'p-1 halfWidth'
        } `}
      >
        <img
          style={{ cursor: 'pointer' }}
          onClick={() =>
            navigate(
              `/product/${product.type}/${product.productId}/${product.productName}`
            )
          }
          src={product.image}
          alt="ad"
          className={`add-first-row-img ${
            mdScreen ? 'fullWidth' : 'img-mx-width'
          }`}
        />
      </div>
      <div
        className={` p-1 ${
          mdScreen
            ? 'fullWidth grid-item-center-align landing-bg-3'
            : 'halfWidth pl-1 '
        }`}
      >
        {product.type === 'auction' && (
          <div className="close-on-text">
            Close on : <BidCountDown date={product.endDate} />
          </div>
        )}
        <div className="big-text-1">New at Thriftzee</div>

        <hr className="hr-style" />
        <div className="big-text-1">
          <div>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() =>
                navigate(
                  `/product/${product.type}/${product.productId}/${product.productName}`
                )
              }
            >
              {product.productName}
            </span>
            {` is available for ${
              product.type === 'buy' ? 'Purchase' : 'Auction'
            }`}
          </div>
        </div>

        <hr className={`hr-style ${mdScreen ? '' : ''}`} />
        <div className="big-text-3 ">
          {product.type === 'buy' ? (
            <>
              Price: <span className="big-text-1">${product.price}</span>
            </>
          ) : (
            <>
              Current Price:{' '}
              <span className="big-text-1">${product.lastBid}</span>
            </>
          )}
        </div>
        <div style={{ padding: '10px 0px' }}>
          {product.type === 'auction' ? (
            <StyledButton
              color={{ xs: '#FF5943', md: '#193C46' }}
              onClick={() => handleQuickBid(product)}
            >
              Place Bid
            </StyledButton>
          ) : (
            <StyledButton
              color={{ xs: '#FF5943', md: '#193C46' }}
              onClick={() => handleQuickBuy(product)}
              pm="70px"
            >
              Buy
            </StyledButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default First;
