import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
// import { Search as SearchIcon } from '@mui/icons-material';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import ProductCards from './components/ProductCards';
import { getProductsData } from './actions';
import { loginUrl } from '../../variables';
import SortModule from '../sort/SortModule';
import { resetProducts } from './reducer';
import ModalWithClose from '../../Components/ModalWithClose';
import QuickBuy from '../../Components/quickBuy';
import QuickBid from '../../Components/QuickBid';
import QuickBidModal from '../../Components/QuickBid/ModalWithClose';
import CommonModal from '../../Components/CommonModal';
import AuctionBuySuccess from '../../Components/productDetails/AuctionBuySuccess';
import ProductListPagination from './components/ProductListPagination';
// import lock from '../../configs/authLock';
import { setRedirect } from '../../utils/redirect';
import {
  setProduct,
  removeProduct,
  getProduct
} from '../../utils/savedProduct';
import Filter from '../filter/Filter';
import { setQuickBuy, isNoBuy, removeBuy } from '../../utils/quickBuyPopUp';
import { setQuickBid, isNoBid, removeBid } from '../../utils/quickBidPopUp';
import AuctionIsOver from '../../Components/productDetails/AuctionIsOver';
import CommonSmallModal from '../../Components/productDetails/CommonSmallModal';
import {
  setProductDetailsStatus,
  setPurchaseStatus
} from '../ProductDetails/reducer';
import { setBidStatus } from '../bidHistory/reducer';
import { fetchStatus, pageSize } from '../../variables';
import ProductSkeleton from './components/ProductListSkeleton';
import ChangeLocation from './components/Location';
import './ProductLists.css';
import EmptyMessage from '../../Components/emptyMessage/EmptyMessage';

const ProductLists = props => {
  // lock.hide();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const loginReducer = useSelector(state => state.loginReducer);
  const storeReducer = useSelector(state => state.storeReducer);
  const productsReducer = useSelector(state => state.productsReducer);
  const productDetailsReducer = useSelector(
    state => state.productDetailsReducer
  );
  const bidHistory = useSelector(state => state.bidHistoryReducer);
  const [showQuickBuy, setshowQuickBuy] = useState(false);
  const [showQuickBid, setshowQuickBid] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showBidSuccess, setShowBidSuccess] = useState(false);
  const [productDetails, setProductDetails] = useState({});
  const [auctionOver, setAucitonOver] = useState(false);
  const { filterProducts, totalCount } = productsReducer;
  const [searchParams] = useSearchParams();

  let type = [];

  if (searchParams.get('type')) {
    type.push(searchParams.get('type'));
  } else {
    type.push('buy');
    type.push('auction');
  }

  useEffect(() => {
    return () => {
      dispatch(setProductDetailsStatus('idle'));
    };
  }, [dispatch, props.type]);

  useEffect(() => {
    dispatch(resetProducts());
    dispatch(
      getProductsData({
        storeId: storeReducer.selectedStores.map(
          selectedStore => selectedStore.id
        )
      })
    );

    document.title = `Thriftzee ${props.type} products`;
  }, [dispatch, storeReducer.selectedStores, props.type]);

  useEffect(() => {
    if (productDetailsReducer.purchaseProductStatus === 'pending') {
      setshowQuickBuy(false);
    }

    if (productDetailsReducer.purchaseProductStatus === 'success') {
      setShowSuccess(true);
    }

    if (bidHistory.placeBidStatus === 'success') {
      setshowQuickBid(false);
      setShowBidSuccess(true);
    }
  }, [
    dispatch,
    productDetailsReducer.purchaseProductStatus,
    bidHistory.placeBidStatus
  ]);

  const handleQuickBuy = useCallback(
    product => {
      if (!loginReducer.isLoggedIn) {
        searchParams.get('type')
          ? setRedirect(pathname + `?type=${searchParams.get('type')}`)
          : setRedirect(pathname);
        setProduct(product);
        setQuickBuy();
        navigate(loginUrl);
      }
      setProductDetails(product);
      setshowQuickBuy(true);
    },
    [loginReducer.isLoggedIn, navigate, searchParams, pathname]
  );

  const handleQuickBid = useCallback(
    product => {
      if (product.endDate < new Date().getTime()) {
        return setAucitonOver(true);
      }
      if (!loginReducer.isLoggedIn) {
        searchParams.get('type')
          ? setRedirect(pathname + `?type=${searchParams.get('type')}`)
          : setRedirect(pathname);
        setProduct(product);
        setQuickBid();
        navigate('/login');
      }
      setProductDetails(product);
      setshowQuickBid(true);
    },
    [loginReducer.isLoggedIn, navigate, searchParams, pathname]
  );

  const handleOkay = () => {
    setAucitonOver(false);
    dispatch(resetProducts());
    dispatch(
      getProductsData({
        storeId: storeReducer.selectedStores.map(
          selectedStore => selectedStore.id
        )
      })
    );
  };

  useEffect(() => {
    if (!isNoBid()) {
      removeBid();
      const product = getProduct();
      removeProduct();
      if (product.endDate < new Date().getTime()) {
        return setAucitonOver(true);
      }
      handleQuickBid(product);
    }
  }, [handleQuickBid]);

  useEffect(() => {
    if (!isNoBuy()) {
      removeBuy();
      const product = getProduct();
      removeProduct();
      handleQuickBuy(product);
    }
  }, [handleQuickBuy]);

  const handleBidSuccessModalClose = () => {
    dispatch(setBidStatus(fetchStatus.IDLE));
    setShowBidSuccess(false);
    dispatch(
      getProductsData({
        storeId: storeReducer.selectedStores.map(
          selectedStore => selectedStore.id
        )
      })
    );
  };

  const handleBuySuccessModalClose = () => {
    dispatch(setPurchaseStatus(fetchStatus.IDLE));
    setShowSuccess(false);
    dispatch(
      getProductsData({
        storeId: storeReducer.selectedStores.map(
          selectedStore => selectedStore.id
        )
      })
    );
  };

  return (
    <>
      <div className="search-filter-bg grey-bg">
        <Container maxWidth="lg" sx={{ py: 2 }}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={{ xs: 2, md: 4 }}
          >
            {/* <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'left' }}>
              <TextField
                variant="standard"
                placeholder="Search Listings"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                  style: {
                    padding: '3px 15px'
                  }
                }}
                sx={{
                  border: { xs: '1px solid white', md: '1px solid #193C46' },
                  borderRadius: '30px',
                  bgcolor: { xs: 'white' },
                  display: 'none'
                }}
              />
            </Grid> */}
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                display: { xs: 'none', md: 'flex' },
                justifyContent: { xs: 'center', md: 'flex-start' }
              }}
            >
              <ChangeLocation />
            </Grid>
            <Grid item xs={12} md={5} textAlign={{ xs: 'center', md: 'right' }}>
              <span className="filter-dropDown">
                <Filter type={type} />
              </span>
              <span className="sort-dropDown">
                <SortModule products={filterProducts} />
              </span>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="grey-bg">
        <Container maxWidth="lg" sx={{ py: 2 }}>
          <div className="location-mobile">
            <ChangeLocation />
          </div>
          {productsReducer.status === fetchStatus.PENDING && (
            <ProductSkeleton />
          )}
          {productsReducer.status === fetchStatus.SUCCESS && (
            <>
              {productsReducer.products.length === 0 && (
                <EmptyMessage
                  emptyIcon={
                    <ProductionQuantityLimitsIcon sx={{ fontSize: 50 }} />
                  }
                >
                  <>
                    <Grid item xs={12}>
                      This store does not have any products available at this
                      moment.
                    </Grid>
                    <Grid item xs={12}>
                      Please visit other stores for available products.
                    </Grid>
                  </>
                </EmptyMessage>
              )}
              <ProductCards
                products={filterProducts}
                handleQuickBuy={handleQuickBuy}
                handleQuickBid={handleQuickBid}
              />
            </>
          )}
          {totalCount > pageSize && (
            <Grid container py={5} justifyContent="center">
              <Grid item>
                <ProductListPagination count={totalCount} />
              </Grid>
            </Grid>
          )}
          <ModalWithClose
            open={showQuickBuy}
            handleClose={() => setshowQuickBuy(false)}
          >
            <QuickBuy product={productDetails} />
          </ModalWithClose>
          <CommonModal
            open={showSuccess}
            handleClick={handleBuySuccessModalClose}
          >
            <AuctionBuySuccess
              type="buy"
              handleClose={() => setShowSuccess(false)}
            />
          </CommonModal>
          <QuickBidModal
            open={showQuickBid}
            handleClose={() => setshowQuickBid(false)}
          >
            <QuickBid
              product={productDetails}
              setAuctionOver={setAucitonOver}
              setshowQuickBid={setshowQuickBid}
            />
          </QuickBidModal>
          <CommonModal
            open={showBidSuccess}
            handleClick={handleBidSuccessModalClose}
          >
            <AuctionBuySuccess
              type="auction"
              handleClose={() => setShowBidSuccess(false)}
            />
          </CommonModal>
          <CommonSmallModal open={auctionOver}>
            <AuctionIsOver handleOkay={handleOkay} />
          </CommonSmallModal>
        </Container>
      </div>
    </>
  );
};

export default ProductLists;
