import React from 'react';
import { Container, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import FooterLeft from './components/FooterLeft';
import FooterMenu from './components/FooterMenu';
// import SocialSection from './components/SocialSection';
import './FooterStyle.css';

const Footer = () => {
  return (
    <div className="bg-darkish">
      <Container maxWidth="lg" className="font-white" sx={{ py: 6 }}>
        <Grid
          container
          className="footer-left"
          spacing={4}
          justifyContent="space-between"
        >
          <Grid item xs={12} md={4}>
            <FooterLeft />
          </Grid>
          <Grid item xs={12} md={2}>
            <FooterMenu />
          </Grid>
          <Grid item xs={12} md={4}>
            {/* <SocialSection /> */}
          </Grid>
          <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                &copy; {new Date().getFullYear()} thriftzee.com
                <Link
                  to="/privacy"
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  {' '}
                  <span className="footer-privacy-color">Privacy Policy</span>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
