import React from 'react';
import HistoryComponent from './components/HistoryComponent';
// import lock from '../../configs/authLock';
import CommonLayout from '../../Layouts/CommonLayout';

const PurchaseHistory = () => {
  // lock.hide();
  return (
    <>
      <CommonLayout>
        <HistoryComponent />
      </CommonLayout>
    </>
  );
};

export default PurchaseHistory;
