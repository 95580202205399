import React from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';
import BidsItem from './BidsItem';
import CircularProgressLoader from '../../../Components/CircularProgressLoader';
import BidPagination from './BidHistoryPagination';
import { fetchStatus } from '../../../variables';
import { useState } from 'react';
import BidderListPagination from './BidderListPagination';

const ProductBidderList = () => {
  const [page, setPage] = useState(1);
  const { bids, status } = useSelector(state => state.bidHistoryReducer);

  return (
    <>
      <Grid container spacing={{ xs: 2, md: 4 }}>
        {status === fetchStatus.PENDING && (
          <Grid item xs={12}>
            <CircularProgressLoader />
          </Grid>
        )}
        {status === fetchStatus.SUCCESS && bids.length ? (
          bids.length > 4 ? (
            <>
              <Grid item xs={12}>
                <BidderListPagination count={page} />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <BidPagination
                  count={bids.length}
                  page={page}
                  setPage={setPage}
                />
              </Grid>
            </>
          ) : (
            bids.map((bid, index) => (
              <Grid item xs={12} key={index}>
                <BidsItem
                  name={bid.userName}
                  time={moment(bid.date).fromNow()}
                  amount={bid.bidAmount}
                />
              </Grid>
            ))
          )
        ) : (
          <Grid item xs={12}>
            <h4 style={{ textAlign: 'center' }}>Be the first to bid</h4>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ProductBidderList;
