import React from 'react';
import { Button } from '@mui/material';

const StyledButton = ({ width, color, children, pm, ...rest }) => {
  return (
    <Button
      sx={{
        width: width,
        px: pm || '48px',
        py: '10px',
        color: 'white',
        bgcolor: color,
        borderRadius: '30px',
        '&:hover': {
          bgcolor: '#FF5943',
          color: 'white'
        },
        textTransform: 'none'
      }}
      variant="contained"
      disableElevation
      {...rest}
    >
      {children}
    </Button>
  );
};

export default StyledButton;
