import { Outlet } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import HeroSection from '../Components/refactor/HeroSection';
import CommonLayout from './CommonLayout';
// import lock from '../configs/authLock';
import { removeBuy } from './../utils/quickBuyPopUp';
import { removeBid } from './../utils/quickBidPopUp';
import { useSelector } from 'react-redux';

const Layout = () => {
  // lock.hide();
  const theme = useTheme();
  const loginReducer = useSelector(state => state.loginReducer);
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  if (!loginReducer.isLoggedIn) {
    removeBid();
    removeBuy();
  }
  return (
    <>
      <CommonLayout>
        {!mdScreen && <HeroSection />}
        <Outlet />
      </CommonLayout>
    </>
  );
};

export default Layout;
