import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  setToken,
  setAuthResult,
  setUserData,
  authResultFetching,
  resetAuth
} from './reducer';
// import lock from '../../configs/authLock';
import { removeRedirectSession } from '../../utils/removeSession';
// import { useLocation } from 'react-router-dom';
import '../../index.css';
// import LoginForm from '../../Components/LoginForm';
import {
  AuthenticationDetails,
  CognitoUser
  // CognitoUserPool
} from 'amazon-cognito-identity-js';
import UserPool from '../../configs/cognitoPoll';
import getUserPoll from '../../utils/getUserPoll';
import { Box, Tab, Tabs } from '@mui/material';
import SignInForm from '../../Components/SignIn/SignInFrom';
import logo from '../../resources/logo.png';
import SignUpForm from '../../Components/SIgnUp/SignUpFrom';
import { getRedirectLink } from '../../utils/redirect';
const Login = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log({ useLocation });
  const { isLoggedIn } = useSelector(state => state.loginReducer);

  useEffect(() => {
    if (window.location.pathname === '/login') {
      window.onpopstate = () => {
        removeRedirectSession();
        navigate('/');
      };
    }
  }, [navigate]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);

  // useEffect(() => {
  //   lock.on('signin submit', function (data) {
  //     dispatch(authResultFetching());
  //   });

  //   lock.on('signup submit', function () {
  //     dispatch(authResultFetching());
  //   });

  //   lock.on('unrecoverable_error', function (error) {
  //     lock.show({
  //       flashMessage: {
  //         type: 'error',
  //         text: error.errorDescription
  //       }
  //     });
  //     dispatch(resetAuth());
  //   });

  //   lock.on('authorization_error', function (error) {
  //     lock.show({
  //       flashMessage: {
  //         type: 'error',
  //         text: error.errorDescription
  //       }
  //     });
  //     dispatch(resetAuth());
  //   });

  //   lock.on('authenticated', function (authResult) {
  //     lock.hide();
  //     navigate('/');
  //     dispatch(setAuthResult(authResult));
  //     lock.getUserInfo(authResult.accessToken, function (error, profileResult) {
  //       dispatch(setToken(authResult.accessToken));
  //       dispatch(setUserData(profileResult));

  //       if (error) {
  //         return;
  //       }
  //     });
  //   });
  // }, [navigate, dispatch]);

  // useEffect(() => {
  //   lock.show();
  // }, []);

  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');

  // const attributes = [
  //   {
  //     Name: 'name',
  //     Value: email
  //   },
  //   {
  //     Name: 'nickname',
  //     Value: email.split('@')[0]
  //   },
  //   {
  //     Name: 'picture',
  //     Value:
  //       'https://s.gravatar.com/avatar/be23508ae554dc55713bda2e2f9569bb?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fbe.png'
  //   },
  //   {
  //     Name: 'phone_number',
  //     Value: '+8801722044555' // phone number wil be dynamic inoput
  //   },
  //   {
  //     Name: 'updated_at',
  //     Value: Date.now().toString()
  //   },
  //   {
  //     Name: 'custom:type',
  //     Value: 'customer'
  //   }
  // ];
  // console.log(attributes);

  // const onSubmit = event => {
  //   event.preventDefault();

  // UserPool.signUp(email, password, attributes, null, (err, data) => {
  //   if (err) console.log(err);
  //   console.log(data);
  // });

  //   const user = new CognitoUser({
  //     Username: email,
  //     Pool: UserPool
  //   });

  //   const authDetails = new AuthenticationDetails({
  //     Username: email,
  //     Password: password
  //   });

  //   user.authenticateUser(authDetails, {
  //     onSuccess: async data => {
  //       // console.log('onSuccess:', data.getRefreshToken().getToken());
  //       const metaData = await getUserPoll(UserPool);
  //       // console.log(metaData.userMeta);
  //       // console.log('onSuccess:', data.getAccessToken().getJwtToken());
  //       // console.log('onSuccess:', data.getAccessToken().payload);
  //       dispatch(setAuthResult(data));
  //       dispatch(setToken(data.getAccessToken().getJwtToken()));
  //       dispatch(setUserData(metaData?.userMeta));
  //       navigate('/');
  //     },

  //     onFailure: err => {
  //       console.error('onFailure:', err);
  //       dispatch(resetAuth());
  //     },

  //     newPasswordRequired: data => {
  //       console.log('newPasswordRequired:', data);
  //     }
  //   });
  // };

  // useEffect(() => {
  //   const user = new CognitoUser({
  //     Username: 'tfsdevweb12346@mailinator.com',
  //     Pool: UserPool
  //   });

  //   const authDetails = new AuthenticationDetails({
  //     Username: 'tfsdevweb12346@mailinator.com',
  //     Password: 'Sa@123456'
  //   });

  //   user.authenticateUser(authDetails, {
  //     onSuccess: async data => {
  //       // console.log('onSuccess:', data);
  //       const metaData = await getUserPoll(UserPool);
  //       // console.log(metaData.userMeta);
  //       // console.log('onSuccess:', data.getAccessToken().getJwtToken());
  //       // console.log('onSuccess:', data.getAccessToken().payload);
  //       dispatch(setAuthResult(data));
  //       dispatch(setToken(data.getAccessToken().getJwtToken()));
  //       dispatch(setUserData(metaData?.userMeta));
  //       // console.log(metaData?.userMeta);
  //       // navigate('/');
  //     },

  //     onFailure: err => {
  //       console.error('onFailure:', err);
  //     },

  //     newPasswordRequired: data => {
  //       console.log('newPasswordRequired:', data);
  //     }
  //   });
  //   // user.getUserAttributes((err, data) => {
  //   //   if (err) {
  //   //     console.log(err);
  //   //   }
  //   //   console.log(data);
  //   // });
  // }, [dispatch]);

  const [tabValue, setTabValue] = useState(0);
  const [authError, setAuthError] = useState(null);
  const [authLoading, setAuthLoading] = useState(false);
  const [acCreateSuccess, setAcCreateSuccess] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    // console.log(newValue);
  };
  // sign in
  const signInOnSubmit = data => {
    dispatch(authResultFetching());
    setAuthLoading(true);
    setAuthError(null);
    const user = new CognitoUser({
      Username: data.email,
      Pool: UserPool
    });

    const authDetails = new AuthenticationDetails({
      Username: data.email,
      Password: data.password
    });

    user.authenticateUser(authDetails, {
      onSuccess: async data => {
        const link = getRedirectLink() || '/';
        const metaData = await getUserPoll(UserPool);
        dispatch(setAuthResult(data));
        dispatch(setToken(data.getAccessToken().getJwtToken()));
        dispatch(setUserData(metaData?.userMeta));
        navigate(link);
        setAuthLoading(false);
        // console.log(metaData?.userMeta);
      },

      onFailure: err => {
        // console.error('onFailure:', err);
        setAuthError(err.message);
        dispatch(resetAuth());
        setAuthLoading(false);
      },

      newPasswordRequired: data => {
        console.log('newPasswordRequired:', data);
        dispatch(resetAuth());
        setAuthLoading(false);
      }
    });
  };

  // sing up
  const sigUpOnSubmit = data => {
    setAuthLoading(true);
    setAuthError(null);
    // setAcCreateSuccess(false);
    // user attributes
    const attributes = [
      {
        Name: 'name',
        Value: data.email
      },
      {
        Name: 'nickname',
        Value: data.email.split('@')[0]
      },
      {
        Name: 'picture',
        Value:
          'https://s.gravatar.com/avatar/be23508ae554dc55713bda2e2f9569bb?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fbe.png'
      },
      {
        Name: 'phone_number',
        Value: data.phoneNumber
      },
      {
        Name: 'updated_at',
        Value: Date.now().toString()
      },
      {
        Name: 'custom:type',
        Value: 'customer'
      }
    ];

    UserPool.signUp(
      data.email,
      data.password,
      attributes,
      null,
      (err, data) => {
        if (err) setAuthError(err.message);
        if (data?.user) setAcCreateSuccess(true);
        setAuthLoading(false);
      }
    );
  };

  return (
    <div className="cognito-container">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ width: '100%', padding: '10px' }}
      >
        <div className="brand-logo">
          <img src={logo} alt="logo" />
        </div>
        <Tabs
          // sx={{ borderBottom: 1, borderColor: 'divider' }}
          value={tabValue}
          onChange={handleTabChange}
        >
          <Tab label="Sign In" />
          <Tab label="Sign Up" />
        </Tabs>

        {tabValue === 0 && (
          <SignInForm
            onSubmit={signInOnSubmit}
            authLoading={authLoading}
            authError={authError}
          ></SignInForm>
        )}
        {tabValue === 1 && (
          <SignUpForm
            onSubmit={sigUpOnSubmit}
            authLoading={authLoading}
            authError={authError}
            isAcSuccess={acCreateSuccess}
          ></SignUpForm>
        )}
      </Box>
    </div>
  );
};

export default Login;
