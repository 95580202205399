import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Container, useMediaQuery, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AuctionProductDetails from './AuctionProductDetails';
import BuyingProductDetails from './BuyingProductDetails';
import CommonLayout from '../../Layouts/CommonLayout';
import { getProductDetails } from './actions';
import { fetchStatus } from '../../variables';
import CircularProgressLoader from '../../Components/CircularProgressLoader';
import './ProductDetailsLayout.css';

const ProductDetailsLayOut = () => {
  const { id, name } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { productDetails, status } = useSelector(
    state => state.productDetailsReducer
  );

  useEffect(() => {
    dispatch(getProductDetails(id));
    document.title = name.trim().replace(/-/g, ' ').toUpperCase();
  }, [dispatch, id, name]);



  return (
    <CommonLayout>
      <div className="product-details-header">
        <Container maxWidth="lg" sx={{ pt: 4, pb: 2 }}>
          <div className="product-header-name">
            {productDetails.productName}
          </div>
        </Container>
      </div>
      <div
        className={
          mdScreen ? 'product-layout-mobile-bg ' : 'product-layout-desktop-bg'
        }
      >
        <Container maxWidth="lg" sx={{ py: 2 }}>
          <Card elevation={0} sx={{ p: { xs: 2, md: 8 } }}>
            {Object.keys(productDetails).length &&
            status === fetchStatus.SUCCESS &&
            productDetails.type === 'buy' ? (
              <BuyingProductDetails />
            ) : productDetails.type === 'auction' ? (
              <AuctionProductDetails />
            ) : (
              <CircularProgressLoader />
            )}
          </Card>
        </Container>
      </div>
    </CommonLayout>
  );
};

export default ProductDetailsLayOut;
