const apiUrl = process.env.REACT_APP_API_URL;
const pageSize = 9;
const bidderPage = 5;
const loginUrl = '/login';
const sortingCriteria = {
  DATE_ASC: 'date_asc',
  DATE_DESC: 'date_desc',
  PRICE_ASC: 'price_asc',
  PRICE_DESC: 'price_desc'
};

const productsStatus = {
  AVAILABLE: 'available',
  ALL: 'all'
};

const productTypes = {
  BUY: 'buy',
  AUCTION: 'auction'
};

const fetchStatus = {
  IDLE: 'idle',
  INITIAL: 'initial',
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error'
};
const auctionStatuses = {
  OPEN: 'open',
  CLOSED: 'closed',
  CANCELLED: 'cancelled'
};
const tfsImageDomain = process.env.REACT_APP_TFS_IMAGE_DOMAIN;
const defaultStoreId = process.env.REACT_APP_DEFAULT_STORE_ID;
const sentryEnvironment = process.env.REACT_APP_SENTRY_ENVIRONMENT;
module.exports = {
  apiUrl,
  loginUrl,
  pageSize,
  sortingCriteria,
  productsStatus,
  productTypes,
  fetchStatus,
  bidderPage,
  auctionStatuses,
  tfsImageDomain,
  defaultStoreId,
  sentryEnvironment
};
