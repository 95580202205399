import { Box, Grid, IconButton, Modal } from '@mui/material';
import { Close } from '@mui/icons-material';
import './quickbid.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  minWidth: 340,
  bgcolor: 'white',
  borderRadius: '8px',
  p: 1,
  outline: 'none',
  border: 'none',
  '&:focus': {
    outline: 'none'
  },
  '&:hover': {
    outline: 'none'
  }
};

const ModalWithClose = ({ open, handleClose, children }) => (
  <Modal
    open={open}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style} className="quickbid-modal">
      <Grid container justifyContent="flex-end">
        <Grid item>
          <IconButton onClick={handleClose} size="small">
            <Close sx={{ fontSize: '35px' }} />
          </IconButton>
        </Grid>
      </Grid>
      {children}
    </Box>
  </Modal>
);

export default ModalWithClose;
