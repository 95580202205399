import { isNoRedirection, removeRedirect } from './redirect';
import { isNoQuickBidAmount, removeQuickBidAmount } from './quickBidAmount';
import { isNoBuy, removeBuy } from './quickBuyPopUp';
import { isNoBid, removeBid } from './quickBidPopUp';
import { isNoProduct, removeProduct } from './savedProduct';

const removeRedirectSession = () => {
  if (!isNoRedirection()) {
    removeRedirect();
  }
  if (!isNoQuickBidAmount()) {
    removeQuickBidAmount();
  }
  if (!isNoBuy()) {
    removeBuy();
  }
  if (!isNoBid()) {
    removeBid();
  }
  if (!isNoProduct()) {
    removeProduct();
  }
};

export { removeRedirectSession };
