import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
// import { setRedirect } from '../utils/redirect';

const PrivateRoutes = () => {
  //   const location = useLocation();
  //   const { pathname } = location;
  const { isLoggedIn } = useSelector(state => state.loginReducer);
  return isLoggedIn ? (
    <Outlet />
  ) : (
    <>
      {/* {setRedirect(pathname)} */}
      <Navigate to="/" />
    </>
  );
};

export default PrivateRoutes;
