import React from 'react';
import {
  Grid,
  Box,
  CardActions,
  Card,
  CardMedia,
  CardContent,
  Typography
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { mockSkeletonData } from './data';

const ProductSkeleton = () => {
  return (
    <div>
      <Grid container spacing={3} className="product-cards-container">
        {mockSkeletonData.map((item, i) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            key={i}
            style={{ borderRadius: '15px' }}
          >
            <Card
              className="product-list-card"
              elevation={0}
              sx={{
                width: '100%',
                '&:hover': { boxShadow: '0px 0px 10px #D3D3D3' }
              }}
            >
              <CardMedia
                className="product-list-card-img"
                sx={{
                  padding: '10px',
                  borderRadius: '20px',
                  cursor: 'pointer',
                  height: '240px'
                }}
              >
                <Skeleton variant="rectangular" width={340} height={230} />
              </CardMedia>
              <CardContent
                sx={{
                  textAlign: { xs: 'center', md: 'left' },
                  height: '100px',
                  cursor: 'pointer'
                }}
                className="product-text"
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  className="product-title"
                  sx={{
                    display: 'flex',
                    justifyContent: { xs: 'center', md: 'flex-start' }
                  }}
                >
                  <Skeleton width={'60%'} />
                </Typography>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  className="product-title"
                  sx={{
                    display: 'flex',
                    justifyContent: { xs: 'center', md: 'flex-start' }
                  }}
                >
                  <Skeleton width={'40%'} />
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  className="product-details"
                  sx={{
                    display: 'flex',
                    justifyContent: { xs: 'center', md: 'flex-start' }
                  }}
                >
                  <Skeleton width={'80%'} />
                </Typography>
              </CardContent>
              <CardActions>
                <Box
                  sx={{
                    display: { xs: 'block', md: 'flex' },
                    width: '100%',
                    justifyContent: 'space-between',
                    p: '2%',
                    alignItems: 'center'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'flex-start' }
                    }}
                  >
                    <Skeleton width={'80px'} height={'60px'} />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'flex-start' }
                    }}
                  >
                    <Skeleton
                      sx={{ borderRadius: '40px' }}
                      width={'130px'}
                      height={'90px'}
                    />
                  </Box>
                </Box>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ProductSkeleton;
